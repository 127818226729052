import React, { useEffect, useState } from "react";
import axiosBaseURL from "../utils/apiBaseUrl";
import { Link, useParams } from "react-router-dom";
import { BlogCard, Loader, MainImage } from "../components";
import { call2 } from "../assets";
import { AiOutlineMail } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";
import { Helmet } from "react-helmet";

const BlogDetails = ({ siteInfo }) => {
  let { slug } = useParams();

    // Handle Active Nav
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(changeNavState());
    }, []);

  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);

  const fetchBlogDetails = async () => {
    if (slug) {
      setLoading(true);
      try {
        await axiosBaseURL.get(`/api/blog/details/${slug}`).then((res) => {
          if (res.data) {
            setLoading(false);
            setDetails(res.data);
          }
        });
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchBlogDetails();
  }, [slug]);

  return (
    <div className="bg-smoke">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{details?.seo_title ? details?.seo_title : ""}</title>
        <meta
          name="description"
          content={
            details?.seo_description
              ? details?.seo_description
              : ""
          }
        />
      </Helmet>
      {/*...Loader...*/}
      {loading && <Loader />}
      {details && !loading && (
        <div>
          <div className="relative w-full md:h-[70vh] overflow-hidden">
            <MainImage
              image={details?.image}
              title={details?.title}
              date={details?.date}
            />
          </div>

          <div className="flex flex-col md:flex-row items-center md:items-start gap-[80px] px-[20px] md:px-[70px] py-[40px] font-poppins">
            <div
              className="grow w-full flex flex-col gap-[20px] text-justify"
              dangerouslySetInnerHTML={{
                __html: details?.content ? details?.content : "",
              }}
            />

            <div className="sticky top-[20px] w-[300px] flex flex-col items-center gap-[30px]">
              {/*...Planning Next Trip...*/}
              <div className="w-full bg-white flex flex-col gap-[10px] shadow-xl rounded-[4px] p-[20px]">
                <p className="font-bold">Planning your next trip?</p>
                <p>
                  Call our customer services team on the number below to speak
                  to one of our advisors who will help you with all of your
                  holiday needs.
                </p>

                <div className="w-full flex flex-col items-start gap-[5px]">
                  <Link
                    to={`tel:${siteInfo?.mobile_no}`}
                    className="hidden md:flex items-center flex-nowrap gap-[5px]"
                  >
                    <img src={call2} alt="" className="w-[17px] h-[17px]" />
                    <p>{siteInfo?.mobile_no ? siteInfo.mobile_no : ""}</p>
                  </Link>
                  <Link
                    to={`mailto:${siteInfo?.email}`}
                    className="hidden md:flex items-center gap-[5px]"
                  >
                    <AiOutlineMail className="font-bold text-lg" />
                    <p>{siteInfo?.email}</p>
                  </Link>
                </div>
              </div>
              {/*...Latest Blog...*/}
              <div className="flex flex-col gap-[20px]">
                <p className="font-bold">Latest Blog by us -</p>

                {details?.Latest?.length > 0 ? (
                  details.Latest.map((item, index) => (
                    <BlogCard key={index} data={item} widthSpecified="w-full" />
                  ))
                ) : (
                  <div>
                    <p>No Data</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogDetails;
