import React, { useEffect, useState } from "react";
import {
  accomodation_icon,
  altitude_icon,
  clock_icon,
  country,
  group_icon,
  location_icon,
  meal,
  transportation_icon,
} from "../assets";

const TripOverview = ({ info }) => {
  const [tripOverviewBoxes, setTripOverviewBoxes] = useState([]);

  useEffect(() => {
    if (info) {
      const boxes = [
        {
          name: "Reference",
          icon: "",
          info: info.reference,
        },
        {
          name: "Duration",
          icon: clock_icon,
          info: info.duration,
        },
        {
          name: "Group Size",
          icon: group_icon,
          info: info.group_size,
        },
        {
          name: "Max Altitude",
          icon: altitude_icon,
          info: info.max_altitude,
        },
        {
          name: "Destination",
          icon: country,
          info: info.destinations,
        },
        {
          name: "Transportation",
          icon: transportation_icon,
          info: info.transportation,
        },
        {
          name: "Arrive On",
          icon: location_icon,
          info: info.arrive_on,
        },
        {
          name: "Departure From",
          icon: location_icon,
          info: info.departure_from,
        },
        {
          name: "Trip Grade",
          icon: "",
          info: info.trip_grade,
        },
        {
          name: "Meals",
          icon: meal,
          info: info.meals,
        },
        {
          name: "Accommodation",
          icon: accomodation_icon,
          info: info.accommodation,
        },
      ];

      setTripOverviewBoxes(boxes);
    }
  }, [info]);

  return (
    <div className="w-full flex flex-col gap-[30px] items-center py-[30px] font-poppins">
      <p className="w-full font-bold text-start">Trip Overview</p>

      <div
        className="w-full flex flex-col gap-[20px] text-justify"
        dangerouslySetInnerHTML={{
          __html: info?.overview ? info?.overview : "",
        }}
      />

      <div className="w-full flex items-center justify-center gap-[20px] flex-wrap">
        {tripOverviewBoxes?.length > 0 &&
          tripOverviewBoxes.map((item, index) => (
            <div
              key={index}
              className="w-[250px] flex items-center gap-[10px] border border-deepBlue px-[20px] py-[6px] rounded-[4px]"
            >
              {item.icon && <img src={item.icon} alt="" />}
              <div className="text-[13px]">
                <p>{item.name}</p>
                <p className="text-darkPink">{item.info}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TripOverview;
