import React, { useEffect, useState } from "react";
import Navtop from "./Navtop";
import { navLinks } from "../data/navlinks";
import { Link, useNavigate } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useSelector } from "react-redux";
import axiosBaseURL from "../utils/apiBaseUrl";
import { RxCross2 } from "react-icons/rx";

const Navbar = ({ scrollingDown, siteInfo }) => {
  const navigate = useNavigate();

  const [destination, setDestination] = useState([]);
  const [peakClimbing, setPeakClimbing] = useState([]);
  const [tourList, setTourList] = useState([]);
  const [trekList, setTrekList] = useState([]);
  const [isTourHovered, setIsTourHovered] = useState(false);
  const [isTrekHovered, setIsTrekHovered] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);

  // Handle Active Nav
  const activeNav = useSelector((state) => state.navState.activeNav);
  const [activeNavId, setactiveNavId] = useState();

  useEffect(() => {
    setactiveNavId(activeNav);
  }, [activeNav]);

  // hANDLE sEARCH qUERY
  const [hoveredItemIndex, setHoveredItemIndex] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchQuery = () => {
    setShowSearchBar(false);
    if (searchQuery) {
      navigate(`/trip-by-destination?search_query=${searchQuery}`);
    }
  };

  // fETCH dESTINATION
  const fetchDestinations = async () => {
    try {
      await axiosBaseURL.get("/api/destinations").then((res) => {
        if (res.data.data.length > 0) {
          setDestination(res.data.data);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  // fETCH Pick climbing
  const fetchPeakClimbing = async () => {
    try {
      await axiosBaseURL.get("/api/peak/climbing").then((res) => {
        if (res.data.data.length > 0) {
          setPeakClimbing(res.data.data);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  // fETCH Tour
  const fetchTour = async () => {
    try {
      await axiosBaseURL.get("/api/tour/list").then((res) => {
        if (res.data.data.length > 0) {
          setTourList(res.data.data);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  // fETCH trek List
  const fetchTrekList = async () => {
    try {
      await axiosBaseURL.get("/api/trekking/list").then((res) => {
        if (res.data.data.length > 0) {
          setTrekList(res.data.data);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDestinations();
    fetchPeakClimbing();
    fetchTour();
    fetchTrekList();
  }, []);

  return (
    <nav
      style={{
        zIndex: "90",
      }}
      className={`${
        scrollingDown
          ? "h-[0px] animate-hide"
          : "h-max fixed top-0 left-0 animate-show"
      } ease-out duration-300 w-full font-poppins text-deepBlue shadow-xl`}
    >
      {/*...Top Section....*/}
      <Navtop siteInfo={siteInfo} />

      {/*...Bottom section...*/}
      <section className="relative hidden bg-deepBlue w-full md:flex items-center justify-end gap-[20px] px-[20px] lg:px-[5rem] py-[10px] border border-b-smoke border-t-0 border-l-0 border-r-0">
        <div className="grow text-smoke flex items-center gap-[20px] lg:gap-[30px] text-center">
          <Link
            to={`/`}
            style={{
              fontWeight: activeNavId === 0 ? "800" : "",
              color: hoveredItemIndex === 0 ? "#E1EFFF" : "",
              textStroke: "1px white",
            }}
            onMouseEnter={() => setHoveredItemIndex(0)}
            onMouseLeave={() => setHoveredItemIndex()}
          >
            Home
          </Link>

          <Link
            to={`/about-us`}
            style={{
              fontWeight: activeNavId === 1 ? "800" : "",
              color: hoveredItemIndex === 1 ? "#E1EFFF" : "",
              textStroke: "1px white",
            }}
            onMouseEnter={() => setHoveredItemIndex(1)}
            onMouseLeave={() => setHoveredItemIndex()}
          >
            About Us
          </Link>

          {destination?.length > 0 && (
            <div
              onMouseEnter={() => setHoveredItemIndex(7)}
              onMouseLeave={() => setHoveredItemIndex()}
              className=""
            >
              <p
                style={{
                  fontWeight: activeNavId === 7 ? "800" : "",
                  color: hoveredItemIndex === 7 ? "#E1EFFF" : "",
                  textStroke: "1px white",
                  cursor: "pointer",
                }}
              >
                Destinations
              </p>

              {hoveredItemIndex === 7 && (
                <div
                  style={{
                    zIndex: "20",
                  }}
                  className="w-full absolute left-0 right-0 top-[30px] text-deepBlue"
                >
                  <div className="w-full h-[40px] bg-transparent"></div>
                  <div className="w-full bg-white flex gap-[50px] flex-wrap px-[20px] lg:px-[100px] py-[10px] py-[30px]">
                    {destination.map((item, index) => (
                      <div key={index} className="flex flex-col items-start">
                        <Link
                          to={`/destination/${item.slug}`}
                          onClick={() => setHoveredItemIndex()}
                          className="font-bold"
                        >
                          {item.title}
                        </Link>
                        <div className="w-full block">
                          {item.slug === "nepal" && (
                            <div className="flex flex-col gap-[20px] text-start pl-[30px] py-[20px]">
                              <div
                                onMouseEnter={() => setIsTourHovered(true)}
                              >
                                <div className="flex items-center gap-[10px]">
                                  <Link
                                    to={`/destination/tour-in-nepal`}
                                    onClick={() => setHoveredItemIndex()}
                                  >
                                    Tour
                                  </Link>

                                  <MdKeyboardDoubleArrowRight />
                                </div>
                                {isTourHovered && tourList?.length > 0 && (
                                  <div className="px-[20px] flex flex-col gap-[10px]">
                                    {tourList.slice(0, 5).map((item, index) => (
                                      <Link
                                        to={`/trip/${item.slug}`}
                                        key={index}
                                        onClick={() => {
                                          setHoveredItemIndex();
                                          setSearchQuery(item.title);
                                        }}
                                        className=""
                                      >
                                        {item.title}
                                      </Link>
                                    ))}
                                  </div>
                                )}
                              </div>

                              <div
                                onMouseEnter={() => {
                                  setIsTrekHovered(true)
                                }}
                                onMouseLeave={() => setIsTrekHovered(false)}
                              >
                                <div className="flex items-center gap-[10px] cursor-pointer">
                                  <Link
                                    to={`/destination/trekking-in-nepal`}
                                    onClick={() => setHoveredItemIndex()}
                                  >
                                    Trekking
                                  </Link>

                                  <MdKeyboardDoubleArrowRight />
                                </div>
                                {isTrekHovered && trekList?.length > 0 && (
                                  <div className="px-[20px] py-[20px] flex flex-col gap-[10px]">
                                    {trekList.slice(0, 2).map((item, index) => (
                                      <Link
                                        to={`/trip/${item.slug}`}
                                        onClick={() => {
                                          setHoveredItemIndex();
                                          setSearchQuery(item.title);
                                        }}
                                        key={index}
                                        className="cursor-pointer"
                                      >
                                        {item.title}
                                      </Link>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          <Link
            to={`/our-team`}
            style={{
              fontWeight: activeNavId === 4 ? "800" : "",
              color: hoveredItemIndex === 4 ? "#E1EFFF" : "",
              textStroke: "1px white",
            }}
            onMouseEnter={() => setHoveredItemIndex(4)}
            onMouseLeave={() => setHoveredItemIndex()}
          >
            Our Team
          </Link>

          {peakClimbing?.length > 0 && (
            <div
              onMouseEnter={() => setHoveredItemIndex(8)}
              onMouseLeave={() => setHoveredItemIndex()}
            >
              <p
                style={{
                  fontWeight: activeNavId === 8 ? "800" : "",
                  color: hoveredItemIndex === 8 ? "#E1EFFF" : "",
                  textStroke: "1px white",
                  cursor: "pointer",
                }}
              >
                Peak Climbing
              </p>

              {hoveredItemIndex === 8 && (
                <div
                  style={{
                    zIndex: "20",
                  }}
                  className="w-full absolute left-0 right-0 top-[30px] text-deepBlue"
                >
                  <div className="w-full h-[40px] opacity-0"></div>
                  <div className="w-full bg-white flex gap-[50px] flex-wrap px-[20px] lg:px-[100px] py-[10px] py-[30px]">
                    {peakClimbing.map((item, index) => (
                      <div key={index}>
                        <Link
                          to={`/trip/${item.slug}`}
                          onClick={() => setSearchQuery(item.title)}
                          className="w-[150px] font-bold"
                        >
                          {item.title}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          <Link
            to={`/gallery`}
            style={{
              fontWeight: activeNavId === 2 ? "800" : "",
              color: hoveredItemIndex === 2 ? "#E1EFFF" : "",
              textStroke: "1px white",
            }}
            onMouseEnter={() => setHoveredItemIndex(2)}
            onMouseLeave={() => setHoveredItemIndex()}
          >
            Gallery
          </Link>

          <Link
            to={`/blogs`}
            style={{
              fontWeight: activeNavId === 3 ? "800" : "",
              color: hoveredItemIndex === 3 ? "#E1EFFF" : "",
              textStroke: "1px white",
            }}
            onMouseEnter={() => setHoveredItemIndex(3)}
            onMouseLeave={() => setHoveredItemIndex()}
          >
            Blogs
          </Link>

          <Link
            to={`/contact-us`}
            style={{
              color: hoveredItemIndex === 5 ? "#E1EFFF" : "",
              fontWeight: activeNavId === 5 ? "800" : "",
              textStroke: "1px white",
            }}
            onMouseEnter={() => setHoveredItemIndex(5)}
            onMouseLeave={() => setHoveredItemIndex()}
          >
            Contact Us
          </Link>
        </div>

        <form
          onSubmit={handleSearchQuery}
          id="search"
          className="flex items-center px-[10px] py-[8px] border border-smoke rounded-[4px] text-smoke bg-transparent "
        >
          <BiSearch className="text-[25px]" />
          <input
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevents the default form submission
                handleSearchQuery(); // Calls the submit method explicitly
              }
            }}
            className="outline-none bg-transparent px-[5px]"
          />
        </form>

        <div className="searchIcon" onClick={() => setShowSearchBar(true)}>
          <BiSearch className="text-[25px] text-smooke" />
        </div>
      </section>

      {showSearchBar && (
        <form
          onSubmit={handleSearchQuery}
          style={{
            zIndex: 50,
          }}
          className="bg-white fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] flex flex-col rounded-xl shadow-xl p-[20px]"
        >
          <div className="flex flex-col relative p-[40px]">
            <div
              onClick={() => setShowSearchBar(false)}
              className="absolute top-[0px] right-[0px] w-[40px] h-[40px] rounded-full flex justify-center items-center text-deepBlue cursor-pointer bg-smoke shadow-xl"
            >
              <RxCross2 className="text-[20px]" />
            </div>
            <div className="flex items-center gap-[10px] px-[20px] py-[30px] px-[10px] py-[8px] border border-deepBlue rounded-[4px] bg-transparent ">
              <BiSearch className="text-[25px] text-deepBlue" />
              <input
                type="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents the default form submission
                    handleSearchQuery(); // Calls the submit method explicitly
                  }
                }}
                className="outline-none bg-transparent px-[5px]"
              />
            </div>
          </div>
        </form>
      )}

      {showSearchBar && (
        <div
          style={{
            zIndex: 49,
          }}
          className="fixed w-full h-[100vh] top-0 left-0 right-0 bottom-0 bg-deepBlue opacity-[75%] "
        />
      )}
    </nav>
  );
};

export default Navbar;
