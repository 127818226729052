import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import axiosBaseURL from "../utils/apiBaseUrl";
import SuccessDialog from "./SuccessDialog";
import ErrorDialog from "./ErrorDialog";
import ReCAPTCHA from "react-google-recaptcha";

const QuickEnquiry = ({ handleCross }) => {
  const recaptchaRef = React.createRef();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [content, setContent] = useState("");
  const [from, setFrom] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [address, setAddress] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  const onChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (from && email && phone && content && address && captchaValue) {
      const formData = {
        from,
        email,
        phone,
        content,
        address,
        name: from,
      };

      try {
        setLoading(true);
        await axiosBaseURL
          .post("/api/enquiry-us/mail", formData)
          .then((res) => {
            setSuccessMessage(res.data.message);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
        setSuccessMessage("");
        setErrorMessage(error.message);
      }
    } else {
      setErrorMessage("Fill all the fields carefully");
    }
  };

  // Handle Reset
  const handleReset = () => {
    setFrom("");
    setPhone("");
    setEmail("");
    setAddress("");
    setContent("");
  };

  return (
    <div
      style={{
        zIndex: 50,
        animation: "enquiryAnimate ease-out 0.5s",
      }}
      className="w-[95%] lg:w-[600px] h-[70%] lg:h-max overflow-y-scroll scrollbar-hide fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] bg-white p-[20px] rounded-[5px] shadow-xl"
    >
      <div className="relative w-full pb-[50px]">
        {/*....Cross.....*/}
        <div
          onClick={handleCross}
          className="absolute top-[0px] right-[0px] w-[40px] h-[40px] rounded-full flex justify-center items-center text-deepBlue cursor-pointer bg-smoke shadow-xl"
        >
          <RxCross2 className="text-[20px]" />
        </div>
        <p className="font-poppins text-[23px] md:text-[40px] font-black text-deepBlue tracking-wider">
          Quick Enquiry
        </p>

        <form
          onSubmit={handleSubmit}
          className="w-full flex flex-col gap-[20px] mt-[30px] px-[20px]"
        >
          <label className="w-full flex flex-col items-start font-bold">
            Full Name
            <input
              type="text"
              placeholder="Fullname"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="w-full border border-deepBlue px-[10px] py-[9px] outline-none bg-transparent rounded-[4px] placeholder:text-deepBlue font-normal font-poppins"
            />
          </label>

          <label className="w-full flex flex-col items-start font-bold">
            Contact Number
            <input
              type="text"
              pattern="[+-]?\d+"
              placeholder="Contact number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="w-full border border-deepBlue px-[10px] py-[9px] outline-none bg-transparent rounded-[4px] placeholder:text-deepBlue font-normal font-poppins"
            />
          </label>

          <label className="w-full flex flex-col items-start font-bold">
            Your Address
            <input
              type="text"
              placeholder="Your address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="w-full border border-deepBlue px-[10px] py-[9px] outline-none bg-transparent rounded-[4px] placeholder:text-deepBlue font-normal font-poppins"
            />
          </label>

          <label className="w-full flex flex-col items-start font-bold">
            Email Address
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full border border-deepBlue px-[10px] py-[9px] outline-none bg-transparent rounded-[4px] placeholder:text-deepBlue font-normal font-poppins"
            />
          </label>

          <label className="w-full flex flex-col items-start font-bold">
            Message(if any)
            <textarea
              placeholder="Message(if any)"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="w-full border border-deepBlue px-[10px] py-[9px] outline-none bg-transparent rounded-[4px] placeholder:text-deepBlue font-normal font-poppins"
            />
          </label>

          <div className="bg-transparent">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lc-hDEmAAAAADwDSZf-KPw3MvqFjKpiSzjkK8IK"
              onChange={onChange}
            />
          </div>

          <div className="w-full flex justify-start">
            {loading ? (
              <div className="relative w-[150px] mt-[30px]">
                <div className="glowing-sun"></div>
                <p className="text-[18px] font-poppins absolute right-[0px] top-[50%] transform -translate-y-[50%] ">
                  Wait...
                </p>
              </div>
            ) : (
              <button
                type="submit"
                className="bg-darkPink px-[20px] py-[7px] rounded-[5px] text-smoke text-[16px]"
              >
                Send Enquiry
              </button>
            )}
          </div>
        </form>
      </div>

      {successMessage && (
        <SuccessDialog
          message={successMessage}
          handleCross={() => {
            setSuccessMessage("");
            handleReset();
            handleCross();
          }}
        />
      )}

      {errorMessage && (
        <ErrorDialog
          mesage={errorMessage}
          handleCross={() => setErrorMessage("")}
        />
      )}

      {(successMessage || errorMessage) && (
        <div
          style={{
            zIndex: 49,
          }}
          className="fixed w-full h-[100vh] top-0 left-0 right-0 bottom-0 bg-deepBlue opacity-[75%] "
        />
      )}
    </div>
  );
};

export default QuickEnquiry;
