import React from "react";
import { call, location3, mail } from "../assets";

const ContactCard = ({ data }) => {
  const { location, phone, email } = data;
  return (
    <div className="w-full bg-darkPink p-[20px] flex flex-col gap-[10px]">
      <div className="flex items-center gap-[5px]">
        <img src={location3} alt="" />
        <p>{location}</p>
      </div>

      <div className="flex items-center gap-[5px]">
        <img src={call} alt="" />
        <p>{phone}</p>
      </div>

      <div className="flex items-center gap-[5px]">
        <img src={mail} alt="" />
        <p>{email}</p>
      </div>
    </div>
  );
};

export default ContactCard;
