import React, { useEffect, useState } from "react";
import {
  BlogCard,
  HeroSection,
  Loader,
  PackageCard,
  Search,
  Slider,
  TestimonialSlider,
  YouTubeVideo,
} from "../components";
import axiosBaseURL from "../utils/apiBaseUrl";
import { changeNavState } from "../reduxStore/navSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import BlogSlider from "../components/BlogSlider";

const Home = ({ video_url, siteInfo }) => {
  // Handle Active Nav
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavState(0));
  }, []);

  const [popularTrekkings, setPopularTrekkings] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [travelChoices, setTravelChoices] = useState([]);
  const [tripCategory, setTripCategory] = useState([]);
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);

  // Get Popular Destinations
  const fetchPopularDestinations = async () => {
    try {
      setLoading(true);
      await axiosBaseURL.get("/api/popular-treeking").then((res) => {
        setLoading(false);
        if (res.data.data.length > 0) {
          console.log(res)
          setPopularTrekkings(res.data.data);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // Get Trip Destinations
  const fetchTripDestinations = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get("/api/trip-destinations").then((res) => {
        setLoading(false);
        if (res.data.data.length > 0 && res.data.data[0].childs?.length > 0) {
          setTripCategory(res.data.data[0]?.childs);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // fETCH Travel Choices
  const fetchTravelChoices = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get("/api/travel-choices").then((res) => {
        setLoading(false);
        if (res.data.data.data.length > 0) {
          setTravelChoices(res.data.data.data);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // fETCH tESTIMONIALS
  const fetchTestimonials = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get("/api/testimonials").then((res) => {
        setLoading(false);
        if (res.data.data.length > 0) {
          setTestimonials(res.data.data);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // fETCH Latest Blogs
  const fetchLatestBlogs = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get("/api/latest/blog/list").then((res) => {
        setLoading(false);
        if (res.data.data.length > 0) {
          setLatestBlogs(res.data.data);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // fETCH cATEGORY lIST fOR sEARCH
  const fetchCategoryList = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get("/api/categories").then((res) => {
        setLoading(false);
        if (res.data.data.length > 0) {
          setCategoryList(res.data.data);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPopularDestinations();
    fetchTestimonials();
    fetchTravelChoices();
    fetchTripDestinations();
    fetchLatestBlogs();
    fetchCategoryList();
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{siteInfo?.seo_title ? siteInfo?.seo_title : ""}</title>
        <meta
          name="description"
          content={siteInfo?.seo_description ? siteInfo?.seo_description : ""}
        />
      </Helmet>
      {loading && <Loader />}

      {!loading && (
        <div className="bg-[#E1EFFF] flex flex-col items-center gap-[50px]">
          <HeroSection categoryList={categoryList} />

          <div
            style={{
              zIndex: 10,
            }}
            className="w-[95%] lg:w-[1100px]"
          >
            <Search categoryList={categoryList} />
          </div>

          {/*...Trekking destinations...*/}
          <div className="w-full flex flex-col items-center px-[20px] md:px-[70px] py-[50px]">
            <div className="w-full text-center font-poppins flex flex-col items-center gap-[20px]">
              <p className="font-[1000] text-xl text-black">
                Popular Trekking Destinations
              </p>
              <p className="text-[#021327] w-full md:w-[500px]">
                The best choice of package we have
              </p>
            </div>
            {/*...Packages...*/}
            <div className="mt-[50px] w-[90%] flex items-center justify-center flex-wrap gap-[50px]">
              {popularTrekkings?.length > 0 &&
                popularTrekkings
                  .slice(0, 6)
                  .map((item, index) => (
                    <PackageCard key={index} data={item} />
                  ))}
            </div>

            <div className="mt-[30px]">
              <button className="text-deepBlue italic underline underline-offset-8 text-lg font-bold decoration-2 decoration-deepBlue">
                <Link to="/popular-trips">Show All</Link>
              </button>
            </div>
          </div>

          {/*...Trip Destinations...*/}
          <div className="bg-deepBlue w-full flex flex-col items-center px-[20px] md:px-[70px] py-[50px] text-white">
            <div className="w-full text-center font-poppins flex flex-col items-center gap-[20px]">
              <p className="font-[1000] text-xl text-white">
                What are you searching?
              </p>
              <p className="w-full md:w-[500px] text-[#E1EFFF]">
                The best choice of private tours
              </p>
            </div>

            {/*...Trip Destinations slider..*/}
            <div className="mt-[50px] w-[95%] overflow-x-hidden flex items-center justify-center flex-wrap gap-[70px]">
              <Slider data={tripCategory} isCategorySlider={true} />
            </div>
          </div>

          {/*....Travel By Choices...*/}
          <div className=" w-full flex flex-col items-center px-[20px] md:px-[70px] py-[50px]">
            <div className="w-full text-center font-poppins flex flex-col items-center gap-[20px]">
              <p className="font-[1000] text-xl">Travel By Choices</p>
              <p className="w-full md:w-[500px] text-[#021327]">
                The best choice of package we have
              </p>
            </div>

            {/*...packages slider..*/}
            <div className="mt-[50px] w-full md:w-[90%] overflow-x-hidden flex items-center justify-center flex-wrap gap-[70px]">
              <Slider data={travelChoices} isPackageSlider={true} />
            </div>
          </div>

          {/*...Explore on Youtube...*/}
          <div className=" w-full flex flex-col items-center px-[20px] md:px-[70px] py-[50px]">
            <div className="w-full text-center font-poppins flex flex-col items-center gap-[20px]">
              <p className="font-[1000] text-xl">Explore us on Youtube</p>
              <p className="w-full md:w-[500px] text-[#021327]">Subscribe Us</p>
            </div>

            <div className="mt-[30px] w-full md:w-[90%]">
              <YouTubeVideo videoId={video_url} />
            </div>
          </div>

          {/*...Client Testimonials....*/}
          <div className=" w-full flex flex-col items-center px-[20px] lg:px-[70px] py-[50px]">
            <div className="w-full text-center font-poppins flex flex-col items-center gap-[20px]">
              <p className="font-[1000] text-xl">Client Testimonials</p>
              <p className="w-full md:w-[500px] text-[#021327]">
                What Our Clients Say?
              </p>
            </div>

            {/*...Testimonials...*/}
            <div
              id="testimonialSlider"
              className=" mt-[30px] flex justify-center"
            >
              <TestimonialSlider data={testimonials} />
            </div>
          </div>

          {/*...Latest Blog....*/}
          <div className="mt-[50px] bg-deepBlue w-full flex flex-col items-center px-[20px] md:px-[70px] py-[50px] text-white">
            <div className="w-full text-center font-poppins flex flex-col items-center gap-[20px]">
              <p className="font-[1000] text-xl text-white">Latest Blogs</p>
              <p className="w-full md:w-[500px] text-[#E1EFFF]">
                Read Our Latest Blogs
              </p>
            </div>
            <div className="w-full flex justify-center py-[30px]">
              <BlogSlider data={latestBlogs} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
