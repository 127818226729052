import React, { useEffect, useState } from "react";
import axiosBaseURL from "../utils/apiBaseUrl";
import { Loader, TeamCard } from "../components";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";
import { Helmet } from "react-helmet";

const OurTeam = () => {

    // Handle Active Nav
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(changeNavState(4));
    }, []);

  const [loading, setLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);

  // fETCH Team members
  const fetchTeamMembers = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get("/api/team/list").then((res) => {
        if (res.data.data.length > 0) {
          setLoading(false);
          setTeamMembers(res.data.data);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  return (
    <div className="w-full flex flex-col items-center gap-[50px] bg-deepBlue font-poppins p-[20px] md:p-[50px]">
       <Helmet>
        <meta charSet="utf-8" />
        <title>Our Experienced Team Members</title>
      </Helmet>
      <div className="w-full text-center">
        <p className="font-poppins text-[40px] font-black text-[#E1EFFF] tracking-wider">
          Our Experince Team
        </p>
      </div>

      {/*...Loader....*/}
      {loading && teamMembers?.length < 1 && <Loader />}

      <div className="w-full md:w-[80%] text-smoke">
        {teamMembers?.length > 0 &&
          teamMembers.map((item, index) => (
            <TeamCard
              key={index}
              name={item.name}
              description={item.description}
              image={item.image}
              position={item.designation}
            />
          ))}
      </div>
    </div>
  );
};

export default OurTeam;
