import React from "react";

const Includes = ({ info }) => {
  return (
    <div className="w-full flex flex-col gap-[30px] items-center py-[30px] font-poppins">
      <p className="w-full font-bold text-start">What’s included?</p>

      <div
        className="w-full flex flex-col gap-[20px] text-justify"
        dangerouslySetInnerHTML={{
          __html: info ? info : "",
        }}
      />
    </div>
  );
};

export default Includes;
