import React, { useEffect } from "react";

const Loader = () => {
  useEffect(() => {
    const path = document.querySelector("#visual path");
    const length = path.getTotalLength();
    path.style.strokeDasharray = length;
    path.style.strokeDashoffset = length;
  }, []);

  return (
    <div className="loader-container">
      <div className="glowing-sun"></div>
      <svg
        id="visual"
        viewBox="0 0 900 600"
        width="900"
        height="100"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
      >
        <path
          d="M0 436L75 533L150 416L225 437L300 510L375 322L450 523L525 457L600 453L675 363L750 454L825 551L900 514"
          fill="none"
          strokeLinecap="square"
          strokeLinejoin="bevel"
          stroke="#052048"
          strokeWidth="10"
        ></path>
      </svg>
    </div>
  );
};

export default Loader;
