import React from "react";

const DateAndPrice = ({ data }) => {
  return (
    <div className="py-[30px]">
      <p className="w-full font-bold text-start text-xl">Excludes</p>
      <div
        className="w-full flex flex-col gap-[20px] text-justify mt-[30px]"
        dangerouslySetInnerHTML={{
          __html: data ? data : "",
        }}
      />
    </div>
  );
};

export default DateAndPrice;
