import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  priceRange: null,
  noOfDays: null,
  accomodation: null,
  tripDifficulty: null,
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    changePriceRange: (state, action) => {
      state.priceRange = action.payload;
    },
    changeNoOfDays: (state, action) => {
      state.noOfDays = action.payload;
    },
    changeAccomodation: (state, action) => {
      state.accomodation = action.payload;
    },
    changeTripDifficulty: (state, action) => {
      state.tripDifficulty = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changePriceRange,
  changeAccomodation,
  changeNoOfDays,
  changeTripDifficulty,
} = filterSlice.actions;

export default filterSlice.reducer;
