import React from "react";

const BubbleAnimation = () => {
  return (
    <div>
      <div
        style={{
          animation: "buble1Animate linear 3s infinite",
        }}
        className="absolute top-[0px] left-[0px] w-[5px] h-[5px] rounded-full border border-darkPink"
      ></div>
      <div
        style={{
          animation: "buble2Animate ease 2s infinite",
        }}
        className="absolute top-[50%] left-[50%] w-[10px] h-[10px] rounded-full border border-darkPink"
      ></div>
      <div
        style={{
          animation: "buble3Animate ease-out 2s infinite",
        }}
        className="absolute top-[13px] right-[0px] w-[2px] h-[2px] rounded-full border border-darkPink"
      ></div>
      <div
        style={{
          animation: "buble4Animate ease-in 3s infinite",
        }}
        className="absolute top-[50%] left-[50%] w-[7px] h-[7px] rounded-full border border-darkPink"
      ></div>
    </div>
  );
};

export default BubbleAnimation;
