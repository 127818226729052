export const noOfDays = [
    {
        name: '1-5 days',
        id: '1-5'
    },
    {
        name: '6-10 days',
        id: '6-10'
    },
    {
        name: '11-15 days',
        id: '11-15'
    },
    {
        name: '16-20 days',
        id: '16-20'
    },
    {
        name: '21-25 days',
        id: '21-25'
    },
    {
        name: '25+ days',
        id: '25%2B'
    }
]

export const tripDifficulty = [
    {
        name: "Easy",
        id: "easy"
    },
    {
        name: "Moderate",
        id: "moderate"
    },
    {
        name: "Hard",
        id: "hard"
    },
]

export const categoryData = [
    {
        name: "Tours in Nepal",
        id: "tours-in-nepal"
    },
    {
        name: "Trekking in Nepal",
        id: "trekking-in-nepal"
    },
    {
        name: "Short Treks from Kathmandu",
        id: "short-treks-from-kathmandu"
    },
]