import React from "react";
import { RxCross2 } from "react-icons/rx";

const ErrorDialog = ({ mesage, handleCross }) => {
  return (
    <div
      style={{
        zIndex: 50,
      }}
      className="fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] "
    >
      <div className="relative w-full md:w-[400px] h-[300px] bg-smoke p-[20px] flex flex-col items-center justify-center gap-[30px] rounded-xl shadow-xl">
        <div className="flex flex-col gap-[15px] font-poppins text-center">
          <p className="text-darkPink text-[25px]">
            <span className="text-red-800 text-[30px]">!</span> Error
          </p>

          <p className="text-deepBlue font-bold text-[16px] whitespace-normal ">{mesage}</p>
        </div>
        <div
          onClick={handleCross}
          className="bg-smoke cursor-pointer absolute top-[20px] right-[20px] w-[30px] h-[30px] rounded-full shadow-lg flex justify-center items-center"
        >
          <RxCross2 className="text-xl text-deepBlue" />
        </div>
      </div>
    </div>
  );
};

export default ErrorDialog;
