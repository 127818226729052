import React, { useState } from "react";
import { call2, logo2 } from "../assets";
import { AiOutlineMail } from "react-icons/ai";
import GoogleTranslator from "./GoogleTranslator";
import { BiLike, BiSearch } from "react-icons/bi";
import { PiGlobe } from "react-icons/pi";
import QuickEnquiry from "./QuickEnquiry";
import { Link, useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { navLinks } from "../data/navlinks";

const Navtop = ({ siteInfo }) => {
  const [hoveredItem, setHoveredItem] = useState();
  const [hamburgerHovered, setHamburgerHovered] = useState(false);
  const [quickEnquiryDisplay, setQuickEnquiryDisplay] = useState(false);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const [activeNavIndex, setActiveNavIndex] = useState();

  // Handle Search
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchQuery = () => {
    if (searchQuery) {
      navigate(`/trip-by-destination?search_query=${searchQuery}`);
    }
  };

  return (
    <section className="relative whitespace-nowrap w-full bg-smoke px-[20px] text-[14px] lg:text-[15px] lg:px-[100px] py-[10px] flex items-center justify-between gap-[10px] text-center">
      <div className="h-full flex items-center gap-[15px] lg:gap-[30px]">
        <Link to="/">
          <img
            src={logo2}
            alt=""
            className="object-contain"
          />
        </Link>

        <Link
          to={`tel:${siteInfo?.mobile_no}`}
          className="hidden md:flex items-center flex-nowrap gap-[5px] px-[15px]"
        >
          <img src={call2} alt="" className="w-[17px] h-[17px]" />
          <p>{siteInfo?.mobile_no ? siteInfo.mobile_no : ""}</p>
        </Link>
        <Link
          to={`mailto:${siteInfo?.email}`}
          className="hidden md:flex items-center gap-[5px]"
        >
          <AiOutlineMail className="font-bold text-lg" />
          <p>{siteInfo?.email}</p>
        </Link>
      </div>

      <div className="w-max hidden md:flex items-center justify-end gap-[15px] lg:gap-[30px] flex-wrap">
        <div
          id="quick-enquiry"
          onClick={() => setQuickEnquiryDisplay(true)}
          className="pr-[15px] lg:pr-[30px] border border-r border-darkPink border-t-0 border-l-0 border-b-0"
        >
          <button className="bg-[#EE4949] py-[5px] px-[20px] md:px-[8px] lg:px-[20px] text-white rounded-[5px] hover:opacity-[75%]">
            Quick Enquiry
          </button>
        </div>

        <div className="flex gap-[15px] lg:gap-[30px]">
          <div
            onMouseEnter={() => setHoveredItem(9)}
            onMouseLeave={() => setHoveredItem()}
            className="w-max z-10 relative flex items-center gap-[5px] cursor-pointer"
          >
            <BiLike
              className={`${
                hoveredItem === 9
                  ? "animate-icon fill-red-400 rotate-[-20deg] text-[22px]"
                  : "text-[20px]"
              } ease duration-200`}
            />

            <p
              className={`${
                hoveredItem === 9 ? "text-darkPink" : ""
              } md:hidden lg:flex ease duration-200`}
            >
              <Link to={siteInfo?.trip_advisor} target="_blank">
                {" "}
                Trip Advisor
              </Link>
            </p>
          </div>

          <div
            onMouseEnter={() => setHoveredItem(10)}
            onMouseLeave={() => setHoveredItem()}
            className="relative cursor-pointer"
          >
            <div className="flex items-center gap-[5px]">
              <PiGlobe
                className={`${
                  hoveredItem === 10
                    ? "animate-icon fill-red-400 rotate-[-20deg] text-[22px]"
                    : "text-[20px]"
                } ease duration-200`}
              />

              <p
                className={`${
                  hoveredItem === 10 ? "text-darkPink" : ""
                } md:hidden lg:flex ease duration-200`}
              >
                Translate
              </p>
            </div>
            <GoogleTranslator
              handleMouseEnter={() => setHoveredItem(10)}
              handleMouseLease={() => setHoveredItem()}
              hoveredItem={10}
            />
          </div>
        </div>
      </div>

      {/*....Hamburger Menu....*/}
      <div
        onMouseEnter={() => setHamburgerHovered(true)}
        onMouseLeave={() => setHamburgerHovered(false)}
        onClick={() => setIsMobileMenuActive(true)}
        className={`absolute top-[50%] right-[20px] transform -translate-y-[50%] ${
          hamburgerHovered
            ? "flex flex-col items-center"
            : "flex flex-col items-center"
        } justify-center gap-[5px] ease duration-300 bg-deepBlue w-[40px] h-[40px] rounded-full cursor-pointer md:hidden`}
      >
        <div className={`w-[20px] h-[2px] bg-smoke rounded-full`}></div>
        <div className={`w-[15px] h-[2px] bg-smoke rounded-full`}></div>
        <div className="flex items-center gap-[4px]">
          <div className={`w-[7px] h-[2px] bg-smoke rounded-full`}></div>
          <div className="w-[2px] h-[2px] rounded-full bg-smoke "></div>
        </div>
      </div>
      {/*....Mobile Menu...*/}
      {isMobileMenuActive && (
        <div
          style={{
            zIndex: 99,
            animation: "navbarAnimate ease 0.2s",
          }}
          className="px-[20px] w-[80%] h-[100%] md:hidden fixed top-[0px] right-0 flex flex-col gap-[30px] overflow-y-scroll scrollbar-hide bg-white transition ease-out delay-1000"
        >
          {/*....Cross...*/}
          <div
            onClick={() => setIsMobileMenuActive(false)}
            className="bg-smoke cursor-pointer absolute top-[20px] right-[20px] w-[30px] h-[30px] rounded-full shadow-lg flex justify-center items-center"
          >
            <RxCross2 className="text-xl text-deepBlue" />
          </div>

          {/*..Nav Items...*/}
          <div className="mt-[70px] flex flex-col gap-[20px]">
            {/*....Quick Enquiry..*/}
            <div
              onClick={() => {
                setIsMobileMenuActive(false);
                setQuickEnquiryDisplay(true);
              }}
              className="pr-[15px] lg:pr-[30px] border border-r border-darkPink border-t-0 border-l-0 border-b-0"
            >
              <button className="bg-[#EE4949] py-[5px] px-[20px] text-white rounded-[5px]">
                Quick Enquiry
              </button>
            </div>
            {/*...Search...*/}
            <form
              onSubmit={handleSearchQuery}
              className="flex items-center px-[15px] py-[8px] border border-smoke rounded-[4px] text-smoke bg-transparent "
            >
              <BiSearch className="text-[30px]" />
              <input
                type="search"
                placeholder="Search"
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents the default form submission
                    setIsMobileMenuActive(false);
                    handleSearchQuery(); // Calls the submit method explicitly
                  }
                }}
                className="grow outline-none bg-transparent text-deepBlue"
              />
            </form>

            <div className="text-smoke flex flex-col items-center gap-[20px] text-center">
              {navLinks?.map((item, index) => (
                <Link
                  key={index}
                  to={`/${item.slug}`}
                  onClick={() => {
                    setActiveNavIndex(index);
                    setIsMobileMenuActive(false);
                  }}
                  style={{
                    textShadow:
                      activeNavIndex === index
                        ? "3px 3px 0 #EE4949,-1px -1px 0 #EE4949,  1px -1px 0 #EE4949, -1px 1px 0 #EE4949, 1px 1px 0 #EE4949"
                        : "",
                    color: activeNavIndex === index ? "#E1EFFF" : "#021327",
                    fontWeight: "700",
                    textStroke: "1px white",
                  }}
                  className=""
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}

      {/*....Show Quick Enquiry Display...*/}
      {quickEnquiryDisplay && (
        <QuickEnquiry handleCross={() => setQuickEnquiryDisplay(false)} />
      )}
      {/*...Overlay...*/}
      {(quickEnquiryDisplay || isMobileMenuActive) && (
        <div
          style={{
            zIndex: 49,
          }}
          className="fixed w-full h-[100vh] top-0 left-0 right-0 bottom-0 bg-deepBlue opacity-[75%] "
        />
      )}
    </section>
  );
};

export default Navtop;
