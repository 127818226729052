import { useEffect, useState } from "react";
import BtnHover from "./BtnHover";

const GoogleTranslator = ({ handleMouseEnter, handleMouseLeave, hoveredItem }) => {

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en", // Set the default language to English (change 'en' to the desired language code)
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <div
      id="google_translate_element"
      className="relative"
      onMouseEnter={handleMouseEnter} // Fixed function calls here
      onMouseLeave={handleMouseLeave} // Fixed function calls here
    >
      {hoveredItem === 10 && <BtnHover />}
    </div>
  );
};

export default GoogleTranslator;
