import React, { useEffect, useState } from "react";
import { ImageLayout, Loader, TeamCard } from "../components";
import { ab1, ab2, ab3, ab4, about_us, team1 } from "../assets";
import axiosBaseURL from "../utils/apiBaseUrl";
import { changeNavState } from "../reduxStore/navSlice";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

const AboutUs = () => {

  const images = {
    image1: ab1,
    image2: ab2,
    image3: ab3,
    image4: ab4,
  };

    // Handle Active Nav
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(changeNavState(1));
    }, []);

  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);

  const fetchDetails = async () => {
    try {
      setLoading(true);
      await axiosBaseURL.get("/api/page/about-us").then((res) => {
        if (res.data) {
          setLoading(false);
          setDetails(res.data);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <div className="bg-smoke">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us</title>
      </Helmet>
      {/*...Loader...*/}
      {loading && !details && <Loader />}
      {!loading && details && (
        <div>
          <div className="w-full bg-deepBlue flex flex-col md:flex-row md:justify-center items-center gap-[50px] text-white font-poppins p-[20px] md:p-[50px]">
            <div>
              <p className="w-max font-poppins text-[40px] text-end font-black text-[#E1EFFF] tracking-wider">
                About US
              </p>
            </div>

            <div className="w-full md:w-max flex flex-col lg:flex-row justify-center items-center gap-[70px]">
              <div style={{
                width: "70%",
              }} className="mask1">
                <img
                  src={details?.data[0]?.image ? details?.data[0]?.image : ""}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col lg:flex-row justify-center items-start gap-[70px] px-[20px] md:px-[50px] py-[40px] font-poppins">
            <div
              className="w-full lg:w-[40%] flex flex-col gap-[30px] text-deepBlue"
              dangerouslySetInnerHTML={{
                __html: details?.data[0]?.content
                  ? details?.data[0]?.content
                  : "",
              }}
            />

            <div className="w-full md:w-[480px]">
              <ImageLayout images={images} />
            </div>
          </div>

          {/*....Experienced team....*/}
          <div className="w-full bg-deepBlue flex flex-col items-center gap-[50px] text-white font-poppins px-[20px] py-[40px] md:p-[50px]">
            <div className="w-full md:w-[40%] text-center flex flex-col gap-[20px]">
              <p className="font-bold text-xl">Our Experienced Team</p>
              <p>
                Nisi amet rhoncus eros pellentesque et ut enim dui. Id viverra
                sit donec nibh cras lectus.
              </p>
            </div>

            <div className="w-[80%] text-smoke">
              {details?.teams.length > 0 &&
                details.teams.map((item, index) => (
                  <TeamCard
                    key={index}
                    name={item.name}
                    description={item.description}
                    image={item.image_url}
                    position={item.designation}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutUs;
