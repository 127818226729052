import React, { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import {
  accomodation,
  noOfDays,
  priceRange,
  tripDifficulty,
} from "../data/filterData";
import { useSelector } from "react-redux";
import {
  changeAccomodation,
  changeNoOfDays,
  changePriceRange,
  changeTripDifficulty,
} from "../reduxStore/filterSlice";
import { RxCross2 } from "react-icons/rx";

const FilterMobile = ({ handleCross, isActive, handleFilter }) => {
  const [moneyRanges, setMoneyRanges] = useState("");
  const [days, setDays] = useState("");
  const [accomodationArray, setAccomodationArray] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle Price Rnage
  const handlePriceRange = (id) => {
    setMoneyRanges(id);
  };

  // Handle Number of Days
  const handleNumberOfDays = (id) => {
    setDays(id);
  };

  // Handle Accomodations
  const handleAccomodations = (id) => {
    setAccomodationArray(id);
  };

  // Handle Trip Difficulty
  const handleTripDifficulty = (id) => {
    setDifficultyLevel(id);
  };

  const handleSubmit = () => {
    handleCross();
    if (moneyRanges || difficultyLevel || days || accomodationArray) {
      handleFilter(moneyRanges, difficultyLevel, days, accomodationArray);
    }
  };

  const handleReset = () => {
    setMoneyRanges([]);
    setAccomodationArray([]);
    setDifficultyLevel([]);
    setDays([]);
  };

  return (
    <div
      style={{
        zIndex: "80",
      }}
      className={`animate-topAnimate fixed top-[50%] left-[0px] right-[0px] transform -translate-y-[50%] w-full h-[70vh] flex justify-evenly flex-wrap gap-[10px] overflow-y-scroll px-[20px] py-[10px] bg-smoke rounded-lg`}
    >
      <div className="w-full flex items-center justify-between border border-b border-[#73767F] border-t-0 border-l-0 border-r-0 py-[5px]">
        <p>Filters</p>
        <p
          onClick={() => handleReset()}
          className="text-darkPink cursor-pointer"
        >
          Reset
        </p>
        <div
          onClick={handleCross}
          className="w-[45px] h-[45px] flex justify-center items-center bg-darkPink rounded-full cursor-pointer"
        >
          <RxCross2 className="text-smoke text-[20px]" />
        </div>
      </div>

      {/*...Price from....*/}
      <div className="border border-b border-[#73767F] border-t-0 border-l-0 border-r-0 py-[5px] text-[15px]">
        <div className="w-full flex items-center justify-between">
          <p className="font-bold text-deepBlue">Price From</p>

          <BsChevronDown />
        </div>
        <div className="flex flex-col gap-[10px] mt-[10px]">
          {priceRange?.map((item, index) => (
            <div key={index} className="flex items-center gap-[10px]">
              <label
                style={{
                  color: moneyRanges?.includes(item.id) ? "#EE4949" : "",
                }}
                className="container"
              >
                {item.range} <span>$</span>
                <input
                  type="checkbox"
                  className="checkbox"
                  name={item.id}
                  value={item.id}
                  id={item.id}
                  readOnly
                  checked={moneyRanges?.includes(item.id)}
                  onClick={() => handlePriceRange(item.id)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          ))}
        </div>
      </div>

      {/*...Number of Days....*/}
      <div className="border border-b border-[#73767F] border-t-0 border-l-0 border-r-0 py-[5px] text-[15px]">
        <div className="w-full flex items-center justify-between">
          <p className="font-bold text-deepBlue">Number of Days</p>

          <BsChevronDown />
        </div>
        <div className="flex flex-col gap-[10px] mt-[10px]">
          {noOfDays?.map((item, index) => (
            <div key={index} className="flex items-center gap-[10px]">
              <label
                htmlFor={item.id}
                style={{
                  color: days?.includes(item.id) ? "#EE4949" : "",
                }}
                className="container"
                onClick={() => handleNumberOfDays(item.id)}
              >
                {item.range}
                <input
                  type="checkbox"
                  className="checkbox"
                  name={item.id}
                  value={item.id}
                  id={item.id}
                  readOnly
                  checked={days?.includes(item.id)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          ))}
        </div>
      </div>

      {/*...Accomodations....*/}
      <div className="border border-b border-[#73767F] border-t-0 border-l-0 border-r-0 py-[5px] text-[15px]">
        <div className="w-full flex items-center justify-between">
          <p className="font-bold text-deepBlue">Accomodations</p>

          <BsChevronDown />
        </div>
        <div className="flex flex-col gap-[10px] mt-[10px]">
          {accomodation?.map((item, index) => (
            <div key={index} className="flex items-center gap-[10px]">
              <label
                htmlFor={item.id}
                style={{
                  color: accomodationArray === item.id ? "#EE4949" : "",
                }}
                className="container"
                onClick={() => handleAccomodations(item.id)}
              >
                {item.name}
                <input
                  type="checkbox"
                  className="checkbox"
                  name={item.id}
                  value={item.id}
                  id={item.id}
                  readOnly
                  checked={accomodationArray === item.id}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          ))}
        </div>
      </div>

      {/*...Trip Difficulty....*/}
      <div className="border border-b border-[#73767F] border-t-0 border-l-0 border-r-0 py-[5px] text-[15px]">
        <div className="w-full flex items-center justify-between">
          <p className="font-bold text-deepBlue">Choose trip difficulty</p>

          <BsChevronDown />
        </div>
        <div className="flex flex-col gap-[10px] mt-[10px]">
          {tripDifficulty?.map((item, index) => (
            <div key={index} className="flex items-center gap-[10px]">
              <label
                htmlFor={item.id}
                style={{
                  color: difficultyLevel === item.id ? "#EE4949" : "",
                }}
                className="container"
                onClick={() => handleTripDifficulty(item.id)}
              >
                {item.name}
                <input
                  type="checkbox"
                  className="checkbox"
                  id={item.id}
                  name={item.id}
                  value={item.id}
                  readOnly
                  checked={difficultyLevel === item.id}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          ))}
        </div>
      </div>

      <div>
        <button
          onClick={handleSubmit}
          className="bg-darkPink px-[20px] py-[8px] text-smoke"
        >
          Filter
        </button>
      </div>
    </div>
  );
};

export default FilterMobile;
