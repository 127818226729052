import React from "react";
import YouTube from "react-youtube";

const YouTubeVideo = ({ videoId }) => {
  // Define the options for the YouTube player
  const opts = {
    height: "460",
    width: "100%",
    playerVars: {
      // Set additional YouTube player options here
    },
  };

  return (
    <iframe
      src={videoId ? videoId : ""}
      title="YouTube Video"
      allowFullScreen
      className="w-full h-[300px] md:h-[370px]"
    ></iframe>
  );
};

export default YouTubeVideo;
