import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axiosBaseURL from "../utils/apiBaseUrl";
import { Loader, PackageCard } from "../components";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";
import { Helmet } from "react-helmet";

const TripByDestination = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("search_query");

  // Handle Active Nav
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavState());
  }, []);

  const [loading, setLoading] = useState(false);
  const [tripData, setTripData] = useState([]);

  const fetchTripByDestination = async (query) => {
    setLoading(true);
    setTripData([]);
    try {
      await axiosBaseURL.get(`/api/site-search?search=${query}`).then((res) => {
        if (res?.data?.trips?.data?.length > 0) {
          setLoading(false);
          setTripData(res.data.trips.data);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (query) {
      fetchTripByDestination(query);
    }
  }, [query]);

  useEffect(() => {
    if (loading) {
      window.scrollTo(0, 0);
    }
  }, [loading]);

  return (
    <div className="bg-smoke">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search Result for {query ? query : ""}</title>
      </Helmet>
      <div className="bg-deepBlue p-[40px]">
        <p className="text-center font-poppins text-[27px] font-black text-[#E1EFFF] tracking-wider">
          Search Results
        </p>
        <p className="text-white text-center font-poppins">
          Search Keywords :{" "}
          <span className="underline underline-offset-8 decoration-smoke px-[10px]">
            {" "}
            {query}{" "}
          </span>
        </p>
      </div>

      {/*...Show Loader....*/}
      {loading === true && tripData.length < 1 && <Loader />}

      {/*...Trip Data...*/}
      <div className="w-full mt-[50px] w-[90%] flex items-center justify-center flex-wrap gap-[50px] pb-[100px]">
        {!loading && tripData?.length > 0 ? (
          tripData.map((item, index) => <PackageCard key={index} data={item} />)
        ) : !loading && tripData?.length < 1 ? (
          <div className="w-full h-[80vh] flex flex-col justify-center items-center font-bold text-deepBlue text-[25px]">
            <p className="font-bold trext-[29px] text-darkPink">Sorry!</p>
            <p>No Data Found</p>
          </div>
        ) : (
          <div className="w-full h-[80vh] flex flex-col justify-center items-center font-bold text-deepBlue text-[25px]">
            <p className="font-bold trext-[29px] text-darkPink">Oops!</p>
            <p>Something Went Wrong</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TripByDestination;
