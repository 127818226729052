import React from "react";

const TestimonialCard = ({ data, isActive, handleClick }) => {
  return (
    <div
      onClick={handleClick}
      className={`${
        isActive
          ? "z-10 bg-white opacity-1"
          : "bg-white opacity-[50%]"
      } max-w-[300px] min-w-[300px] h-[360px] flex flex-col justify-between gap-[10px] font-poppins p-[10px] rounded-lg shadow-lg cursor-pointer transition transform duration-500 ease-out`}
    >
      <div
        className="grow flex flex-col justify-center gap-[6px] text-sm text-[#021327]"
        dangerouslySetInnerHTML={{
          __html:
            data?.content.length > 400
              ? data.content.slice(0, 400) + "..."
              : data.content,
        }}
      />

      <div className="flex items-center gap-[10px]">
        <img
          src={data.image}
          alt=""
          className="w-[50px] h-[50px] rounded-full"
        />
        <div>
          <p className="font-bold text-[#021327]">{data.name}</p>
          <p className="text-[#021327]">{data.country}</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
