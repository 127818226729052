import React, { useState } from "react";
import { Link } from "react-router-dom";
import BubbleAnimation from "./BubbleAnimation";

const BlogCard = ({ data, widthSpecified }) => {
  const { title, date, image_url, slug } = data;

  const [isBtnHovered, setIsBtnHovered] = useState(false);

  return (
    <div
      className={`${
        widthSpecified
          ? widthSpecified
          : "min-w-[300px] max-w-[300px] md:min-w-[370px] md:max-w-[370px]"
      } h-[430px] flex flex-col items-center justify-between gap-[10px] bg-white rounded-[4px] font-poppins px-[20px] py-[10px]`}
    >
      <div className="w-full flex flex-col items-start gap-[3px]">
        <p className="font-bold text-black">
          {title?.length > 70 ? title.slice(0, 70) + "..." : title}
        </p>
        <p className="text-[10px] text-deepBlue">{date}</p>
      </div>

      <img src={image_url} alt="" className="w-full h-[180px] object-cover" />

      <div
        className="text-deepBlue text-[14px]"
        dangerouslySetInnerHTML={{
          __html:
            data?.content.length > 200
              ? data.content.slice(0, 200) + "..."
              : data.content,
        }}
      />

      <Link to={`/blog/${slug}`}>
        <button
          onMouseEnter={() => setIsBtnHovered(true)}
          onMouseLeave={() => setIsBtnHovered(false)}
          className="relative border border-darkPink text-darkPink hover:font-bold px-[30px] py-[10px] ease duration-150"
        >
          Read More
          {isBtnHovered && <BubbleAnimation />}
        </button>
      </Link>
    </div>
  );
};

export default BlogCard;
