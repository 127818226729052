import React, { useEffect, useState } from "react";
import axiosBaseURL from "../utils/apiBaseUrl";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";
import { Link } from "react-router-dom";
import { Loader } from "../components";
import { Helmet } from "react-helmet";

const Album = () => {
  // Handle Active Nav
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavState(2));
  }, []);

  const [albumData, setAlbumData] = useState([]);
  const [hoveredId, setHoveredId] = useState();
  const [loading, setLoading] = useState(false);

  const fetchAlbum = async () => {
    try {
      setLoading(true);
      await axiosBaseURL.get("/api/albums").then((res) => {
        if (res.data.data) {
          setLoading(false);
          setAlbumData(res.data.data);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAlbum();
  }, []);

  return (
    <div className="w-full bg-smoke flex flex-col items-center gap-[50px] text-white font-poppins">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Album</title>
      </Helmet>
      <div className="w-full bg-deepBlue p-[20px] md:p-[50px]">
        <p className="text-center font-poppins text-[40px] font-black text-[#E1EFFF] tracking-wider">
          GALLERY
        </p>
      </div>

      {loading && <Loader />}

      <div className="bg-smoke  w-full flex items-center justify-center gap-[60px] flex-wrap py-[70px] px-[20px]">
        {!loading &&
          albumData?.length > 0 &&
          albumData.map((item, index) => (
            <div
              key={index}
              onMouseEnter={() => setHoveredId(index)}
              onMouseLeave={() => setHoveredId()}
              className="relative w-full md:w-[350px] h-[320px] rounded-[4px] shadow-xl"
            >
              <img
                src={item.image}
                alt=""
                className="w-full h-full object-cover rounded-[4px]"
              />
              <div className="w-full absolute bottom-[0px] text-center bg-darkPink text-smoke px-[10px] py-[10px] rounded-b-[4px]">
                <p>{item.title}</p>
              </div>

              {/*Hover Overlay*/}
              {hoveredId === index && (
                <div
                  style={{
                    zIndex: 20,
                  }}
                  className="w-full h-full absolute top-0 left-0 right-0 bottom-0 bg-black/50"
                >
                  <button className="absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] bg-darkPink px-[20px] py-[8px] text-smoke rounded-[]">
                    <Link to={`/gallery/${item.slug}`}>Explore</Link>
                  </button>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Album;
