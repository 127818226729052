import React from "react";
import { dateFormatter } from "../utils/dateFormatter";

const MainImage = ({ image, title, date }) => {

  return (
    <div className="relative">
      <img src={image ? image : ""} alt="" className="sliderImage" />
      <div className="absolute top-[0px] w-full bg-gradient-to-b from-deepBlue to-transparent p-[30px] font-poppins text-center text-smoke">
        {title && <p className="font-bold text-[35px]">{title}</p>}

        {date && <p className="">{dateFormatter(date)}</p>}
      </div>
    </div>
  );
};

export default MainImage;
