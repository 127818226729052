import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {

    const navigate = useNavigate();
    
  return (
    <div className="w-full h-[85vh] flex flex-col justify-center items-center gap-[10px]">
      <div className="relative">
        <p className="font-poppins text-[50px] font-bold text-deepBlue">404</p>
        <div style={{
            zIndex: -1
        }} className="absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] bg-darkPink w-[200px] h-[50px] -skew-y-12"></div>
      </div>
      <p className="font-poppins text-[50px] font-bold text-deepBlue">
        Page Not Found
      </p>

      <button onClick={()=> navigate(-1)} className="w-[300px] bg-darkPink px-[30px] py-[8px] text-smoke text-[25px] font-bold">
        Go Back
      </button>
    </div>
  );
};

export default PageNotFound;
