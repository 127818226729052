import React, { useEffect, useState } from "react";
import axiosBaseURL from "../utils/apiBaseUrl";
import { ImageView, Loader } from "../components";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";
import { Helmet } from "react-helmet";

const GalleryImages = () => {
  let { slug } = useParams();

  // Handle Active Nav
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavState());
  }, []);

  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayedImage, setDisplayedImage] = useState("");

  const fetchGalleryData = async (slug) => {
    try {
      setLoading(true);
      await axiosBaseURL.get(`/api/albums-single/${slug}`).then((res) => {
        if (res.data.projects) {
          setLoading(false);
          setImages(res.data.projects);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (slug) {
      fetchGalleryData(slug);
    }
  }, [slug]);

  return (
    <div className="w-full bg-smoke px-[20px] py-[50px]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gallery Images</title>
      </Helmet>
      {loading && images?.length < 1 && <Loader />}

      {slug && (
        <p className="font-poppins font-bold text-[35px] text-deepBlue text-center">
          {slug}
        </p>
      )}
      <div className="flex items-center justify-center gap-[50px] flex-wrap mt-[30px]">
        {!loading && images?.length > 0 ? (
          images?.map((item, index) => (
            <div
              key={index}
              onClick={() => setDisplayedImage(item.image)}
              className="w-full md:w-[350px] h-[320px] rounded-[4px] cursor-pointer"
            >
              <img
                src={item.image}
                alt=""
                className="w-full h-full object-cover rounded-[4px]"
              />
              <p className="font-poppins py-[5px] font-medium">{item.title}</p>
            </div>
          ))
        ) : !loading && images?.length < 1 ? (
          <div className="w-full h-[80vh] flex flex-col justify-center items-center font-bold text-deepBlue text-[25px]">
            <p className="font-bold trext-[29px] text-darkPink">Sorry!</p>
            <p>No Data Found</p>
          </div>
        ) : (
          <div className="w-full h-[80vh] flex flex-col justify-center items-center font-bold text-deepBlue text-[25px]">
            <p className="font-bold trext-[29px] text-darkPink">Oops!</p>
            <p>No Data Found</p>
          </div>
        )}
      </div>

      {displayedImage && (
        <ImageView
          image={displayedImage}
          handleCross={() => setDisplayedImage("")}
        />
      )}

      {displayedImage && (
        <div
          style={{
            zIndex: 99,
          }}
          className="fixed w-full h-[100vh] top-0 left-0 right-0 bottom-0 bg-deepBlue opacity-[75%] "
        />
      )}
    </div>
  );
};

export default GalleryImages;
