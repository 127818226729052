import React, { useEffect, useRef, useState } from "react";
import PackageCard from "./PackageCard";
import CategoryCard from "./CategoryCard";
import { BsArrowLeftShort } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";

const Slider = ({ data, isPackageSlider, isCategorySlider }) => {
  const sliderRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollDistance = -320; // Adjust this value to control the scroll distance
  const [mobileDisplayData, setMobileDisplayData] = useState();
  const [dataIndex, setDataIndex] = useState(0);

  // For Mobile view
  useEffect(() => {
    if (data?.length > 0) {
      setMobileDisplayData(data[dataIndex]);
    }
  }, [data, dataIndex]);

  const handleNextClick = () => {
    const newPosition = scrollPosition + scrollDistance;
    sliderRef.current.style.transform = `translate(${newPosition}px, 0px)`;
    setScrollPosition(newPosition);
  };

  const handlePrevClick = () => {
    sliderRef.current.style.transform = `translate(0px, 0px)`;
    setScrollPosition(0);
  };

  const handleNextBtn = () => {
    if (dataIndex < data?.length - 1) {
      setDataIndex((prev) => prev + 1);
    }
  };

  const handlePrevBtn = () => {
    if (dataIndex > 0) {
      setDataIndex((prev) => prev - 1);
    }
  };

  return (
    <div className="w-full">
      {data?.length > 0 && (
        <div className="hidden relative w-full md:flex justify-center overflow-hidden">
          <div className="w-[87%] relative flex justify-center overflow-hidden">
            <div
              ref={sliderRef}
              className="w-full flex items-center justify-between gap-[70px]"
              style={{
                transition: "all 0.3s ease-in-out",
              }}
            >
              {isPackageSlider ? (
                data.map((item, index) => (
                  <PackageCard key={index} data={item} />
                ))
              ) : isCategorySlider ? (
                data.map((item, index) => (
                  <CategoryCard key={index} data={item} />
                ))
              ) : (
                <div></div>
              )}
            </div>
            {/*....Blur....*/}
            {isPackageSlider && (
              <div className="">
                <div className="sliderBlurLeft"></div>
                <div className="sliderBlurRight"></div>
              </div>
            )}
          </div>
          <div
            className="z-20 absolute left-0 top-[50%] transform -translate-y-[50%] bg-white w-[50px] h-[50px] rounded-full flex justify-center items-center text-black text-[30px] font-bold shadow-xl cursor-pointer"
            onClick={handlePrevClick}
          >
            <BsArrowLeftShort />
          </div>
          <div
            className="z-20 absolute right-0 top-[50%] transform -translate-y-[50%] bg-white w-[50px] h-[50px] rounded-full flex justify-center items-center text-black text-[30px] font-bold shadow-xl cursor-pointer"
            onClick={handleNextClick}
          >
            <BsArrowRightShort />
          </div>
        </div>
      )}

      {/*...For Mobile View...*/}
      {mobileDisplayData && (
        <div className="relative w-full flex justify-center px-[70px] md:hidden">
          <div>
            {isPackageSlider ? (
              <PackageCard data={mobileDisplayData} />
            ) : isCategorySlider ? (
              <CategoryCard data={mobileDisplayData} />
            ) : (
              <div></div>
            )}
          </div>

          <div
            className="lg:hidden z-20 absolute left-0 top-[50%] transform -translate-y-[50%] bg-white w-[50px] h-[50px] rounded-full flex justify-center items-center text-black text-[30px] font-bold shadow-xl cursor-pointer"
            onClick={handlePrevBtn}
          >
            <BsArrowLeftShort />
          </div>
          <div
            className="lg:hidden z-20 absolute right-0 top-[50%] transform -translate-y-[50%] bg-white w-[50px] h-[50px] rounded-full flex justify-center items-center text-black text-[30px] font-bold shadow-xl cursor-pointer"
            onClick={handleNextBtn}
          >
            <BsArrowRightShort />
          </div>
        </div>
      )}
    </div>
  );
};

export default Slider;
