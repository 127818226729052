import React from "react";

const ImageLayout = ({ images }) => {
  const { image1, image2, image3, image4 } = images;

  return (
    <div className="w-full flex flex-col gap-[3px]">
      <div className="h-[260px] flex items-end gap-[3px] ">
        <img src={image1} alt="" className="w-[60%] h-[80%] object-cover" />
        <img src={image2} alt="" className="w-[40%] h-full object-cover" />
      </div>
      <div className="h-[260px] flex items-start gap-[3px] ">
        <img src={image3} alt="" className="w-[40%] h-full object-cover" />
        <img src={image4} alt="" className="w-[50%] h-[65%] object-cover" />
      </div>
    </div>
  );
};

export default ImageLayout;
