import React, { useState } from "react";
import { hiker, altitude, location2, package1 } from "../assets";
import { Link } from "react-router-dom";

const PackageCard = ({ data, inLandscape }) => {
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [isViewBtnClicked, setIsViewBtnClicked] = useState(false);

  return (
    <div
      className={`${
        inLandscape
          ? "w-[90%] h-[300px] flex items-center justify-between gap-[20px]"
          : "min-w-[280px] max-w-[280px] h-[490px] flex flex-col justify-between gap-[10px] rounded-lg"
      } bg-white p-[10px] shadow-xl`}
    >
      <img
        src={data?.image_url ? data.image_url : package1}
        alt=""
        className={`${
          inLandscape ? "w-[50%] h-full" : "w-full h-[200px] rounded-xl"
        } object-cover`}
      />
      <div
        className={`${
          inLandscape
            ? "w-[40%] h-full flex flex-col justify-between gap-[10px]"
            : "w-full flex flex-col gap-[10px]"
        } text-[15px]`}
      >
        <p className="text-[#EE4949] text-[17px] font-bold font-poppins">
          {data.title}
        </p>

        <div className="flex items-center gap-[5px]">
          <img
            src={hiker}
            alt=""
            className="w-[20px] h-[20px] object-contain "
          />
          <p>
            Difficulty : <span>{data.trip_grade}</span>
          </p>
        </div>

        <div className="flex items-center gap-[5px]">
          <img
            src={altitude}
            alt=""
            className="w-[20px] h-[20px] object-contain "
          />
          <p>
            Altitude : <span>{data.max_altitude}</span>
          </p>
        </div>

        <div className="flex flex-col items-end">
          <p className="text-sm">*price starting from</p>
          <p className="text-[#EE4949] text-xl font-[900] font-poppins">
            US$ <span>{data.price}</span>
          </p>
        </div>

        <div className="flex items-center justify-between gap-[20px] font-poppins">
          <div
            onMouseEnter={() => setIsViewBtnClicked(true)}
            onMouseLeave={() => setIsViewBtnClicked(false)}
            className="relative w-[50%] "
          >
            <Link to={`/trip/${data?.slug}`}>
              <button
                className={`w-full ${
                  isViewBtnClicked
                    ? "bg-transparent text-darkPink font-bold"
                    : "text-[#EE4949]"
                } ease duration-300 border border-[#EE4949] px-[5px] py-[8px] rounded-[4px]`}
              >
                View Details
              </button>
            </Link>
            {isViewBtnClicked && (
              <div>
                <div className="absolute top-[-20px] left-[-20px] w-[20px] h-[20px] rounded-full bg-darkPink animate-sprinkle"></div>
                <div className="absolute top-[-5px] right-[-5px] w-[5px] h-[5px] rounded-full bg-darkPink animate-sprinkle"></div>
                <div className="absolute bottom-[-5px] left-[-5px] w-[10px] h-[10px] rounded-full bg-darkPink animate-sprinkle"></div>
                <div className="absolute bottom-[-5px] right-[-5px] w-[15px] h-[15px] rounded-full bg-darkPink animate-sprinkle"></div>
              </div>
            )}
          </div>

          <div
            onMouseEnter={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
            className="relative w-[50%]"
          >
            <Link to={`/book-trip/${data?.slug}`}>
              <button
                className={`w-full px-[20px] py-[8px] ${
                  isButtonHovered
                    ? "bg-transparent text-darkPink font-bold"
                    : "bg-[#EE4949] text-white"
                } ease duration-300 border border-[#EE4949] rounded-[4px]`}
              >
                Book
              </button>
            </Link>

            {isButtonHovered && (
              <div>
                <div className="absolute top-[-20px] left-[-20px] w-[20px] h-[20px] rounded-full bg-darkPink animate-sprinkle"></div>
                <div className="absolute top-[-5px] right-[-5px] w-[5px] h-[5px] rounded-full bg-darkPink animate-sprinkle"></div>
                <div className="absolute bottom-[-5px] left-[-5px] w-[10px] h-[10px] rounded-full bg-darkPink animate-sprinkle"></div>
                <div className="absolute bottom-[-5px] right-[-5px] w-[15px] h-[15px] rounded-full bg-darkPink animate-sprinkle"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
