import React, { useEffect, useState } from "react";
import { FaAngleRight, FaChevronDown } from "react-icons/fa";

const Iteneraries = ({ info }) => {

  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (info?.length > 0) {
      setData(info);
    }
  }, [info]);

  return (
    <div className="w-full flex flex-col gap-[30px] py-[30px] font-poppins">
      <p className="w-full font-bold text-start">Trip Overview</p>
      <div className="w-full md:w-[80%] flex flex-col gap-[20px]">
        {data?.length > 0 &&
          data?.map((item, index) => (
            <div
              className="p-[10px] border border-deepBlue rounded-[4px]"
              onClick={() => {
                setActiveIndex(index);
                if (activeIndex === index && isActive) {
                  setIsActive(false);
                } else {
                  setIsActive(true);
                }
              }}
              key={index}
            >
              <div
                className="accordion-title flex justify-between cursor-pointer"
                onClick={() => setIsActive(!isActive)}
              >
                <div>
                  <p
                    className={`${
                      isActive && activeIndex === index
                        ? "text-darkPink text-[19px]"
                        : "text-darkPink text-[17px]"
                    }`}
                  >
                    {item?.title}
                  </p>
                </div>
                <div className="text-darkPink">
                  {isActive && activeIndex === index ? (
                    <FaChevronDown
                      className={`${
                        isActive && activeIndex === index
                          ? "text-[21px]"
                          : "text-[21px]"
                      }`}
                    />
                  ) : (
                    <FaAngleRight
                      className={`${
                        isActive && activeIndex === index
                          ? "text-[21px]"
                          : "text-[21px]"
                      }`}
                    />
                  )}
                </div>
              </div>
              {isActive && activeIndex === index && (
                <div className="text-[15px] py-[10px]">
                  <p>{item.content}</p>
                </div>
              )}{" "}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Iteneraries;
