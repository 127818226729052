import React from 'react'

const BtnHover = () => {
  return (
    <div style={{
        zIndex: "-1"
    }} className='w-[20px] h-[20px] rounded-full absolute top-[50%] left-[50%] right-0 bottom-0 transform -translate-x-[50%] -translate-y-[50%] bg-darkPink animate-swaggy'>

    </div>
  )
}

export default BtnHover