import React from "react";

const TeamCard = ({ description, image, name, position }) => {
  return (
    <div className="w-full flex flex-col lg:flex-row md:justify-between lg:justify-center items-center gap-[30px] lg:gap-[70px]">
      <div className="grow lg:w-[40%]">
        <div
          dangerouslySetInnerHTML={{
            __html:
              description && description
          }}
        />
      </div>

      <div className="flex flex-col items-center gap-[5px] text-center">
        <img
          src={image}
          alt=""
          className="min-w-[300px] max-w-[300px] h-[300px] rounded-full"
        />
        <p className="w-full font-bold text-[17px]">{name}</p>
        <p className="text-[13px] mt-[-5px]">{position}</p>
      </div>
    </div>
  );
};

export default TeamCard;
