import React, { useState } from "react";
import { location, calender, category, dropdown } from "../assets";
import {
  noOfDays,
  categoryData,
  tripDifficulty,
} from "../data/searchParameters";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "./ErrorDialog";

const Search = ({ categoryList }) => {
  const navigate = useNavigate();

  const [isNoOfDaysFocused, setIsNoOfDaysFocused] = useState(false);
  const [isCategoryFocused, setIsCategoryFocused] = useState(false);
  const [isDiffultyLevelFocused, setIsDiffultyLevelFocused] = useState(false);
  const [isSearchBtnHovered, setIsSearchBtnHovered] = useState(false);

  const [destination, setDestination] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [days, setDays] = useState("");
  const [daysId, setDaysId] = useState("");
  const [categorySlug, setCategorySlug] = useState("");
  const [difficultyId, setDifficultyId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault()
    if (destination) {
      navigate(
        `/search?destination=${destination}&days=${daysId}&category=${categorySlug}&difficulty=${difficultyId}`
      );
    } else {
      setErrorMessage("Fill Destination Field!");
      return;
    }
  };

  return (
    <div className="w-full bg-[#EE4949] text-white font-poppins p-[20px] rounded-lg">
      <p className="text-center font-[800] tracking-wider">
        Search for your Destinations
      </p>

      <form
        onSubmit={handleSubmit}
        className="w-full flex items-center justify-center flex-wrap gap-[25px] mt-[20px] text-[15px]"
      >
        {/*...Enter destination...*/}
        <div className="flex flex-col items-start">
          <p className="font-[500]">Enter the destination</p>
          <div className="flex items-center gap-[5px] border border-white border-b border-t-0 border-l-0 border-r-0 py-[5px]">
            <img
              src={location}
              alt=""
              className="w-[20px] h-[20px] object-contain"
            />
            <input
              type="text"
              placeholder="Destination"
              value={destination}
              onChange={(e)=> setDestination(e.target.value)}
              className="bg-transparent outline-none placeholder:text-slate-300"
            />
          </div>
        </div>

        {/*...Choose Number of Days...*/}
        <div className="relative">
          <p className="font-[500]">Choose number of days</p>
          <div className="flex items-center gap-[10px] border border-white border-b border-t-0 border-l-0 border-r-0 py-[5px]">
            <img
              src={calender}
              alt=""
              className="w-[20px] h-[20px] object-contain"
            />
            <input
              type="text"
              placeholder="1-5 days"
              readOnly
              value={days}
              className="bg-transparent outline-none placeholder:text-slate-300"
              onClick={() => {
                setIsDiffultyLevelFocused(false);
                setIsCategoryFocused(false);
                setIsNoOfDaysFocused((prev) => !prev);
              }}
            />
          </div>

          {isNoOfDaysFocused && (
            <ul
              style={{
                zIndex: "10",
              }}
              className="animate-show w-full absolute top-[100%] left-[50%] transform -translate-x-[50%] bg-white px-[10px] py-[20px] rounded-lg flex flex-col gap-[20px] shadow-xl"
            >
              {noOfDays.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setDays(item.name);
                    setIsNoOfDaysFocused(false);
                    setDaysId(item.id)
                  }}
                  className="text-black tracking-wider cursor-pointer hover:text-red-500"
                >
                  {item.name}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/*....Trip difficulty...*/}
        <div className="relative">
          <p className="font-[500]">Trip Difficulty</p>
          <div className="flex items-center gap-[10px] border border-white border-b border-t-0 border-l-0 border-r-0 py-[5px]">
            <img
              src={dropdown}
              alt=""
              className="w-[20px] h-[20px] object-contain"
            />
            <input
              type="text"
              placeholder="easy"
              readOnly
              value={difficulty}
              className="bg-transparent outline-none placeholder:text-slate-300"
              onClick={() => {
                setIsCategoryFocused(false);
                setIsNoOfDaysFocused(false);
                setIsDiffultyLevelFocused((prev) => !prev);
              }}
            />
          </div>

          {isDiffultyLevelFocused && (
            <ul
              style={{
                zIndex: "10",
              }}
              className="animate-show w-full absolute top-[100%] left-[50%] transform -translate-x-[50%] bg-white px-[10px] py-[20px] rounded-lg flex flex-col gap-[20px] shadow-xl"
            >
              {tripDifficulty.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setDifficulty(item.name);
                    setIsDiffultyLevelFocused(false);
                    setDifficultyId(item.id)
                  }}
                  className="text-black tracking-wider cursor-pointer hover:text-red-500"
                >
                  {item.name}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/*....Category...*/}
        <div className="relative">
          <p className="font-[500]">Category</p>
          <div className="flex items-center gap-[10px] border border-white border-b border-t-0 border-l-0 border-r-0 py-[5px]">
            <img
              src={category}
              alt=""
              className="w-[20px] h-[20px] object-contain"
            />
            <input
              type="text"
              placeholder="Tours in Nepal"
              readOnly
              value={categoryName}
              className="bg-transparent outline-none placeholder:text-slate-300"
              onClick={() => {
                setIsDiffultyLevelFocused(false);
                setIsNoOfDaysFocused(false);
                setIsCategoryFocused((prev) => !prev);
              }}
            />
          </div>
          {isCategoryFocused && (
            <ul
              style={{
                zIndex: "10",
              }}
              className="animate-show w-full h-[300px] overflow-y-scroll absolute top-[100%] left-[50%] transform -translate-x-[50%] bg-white px-[10px] py-[20px] rounded-lg flex flex-col gap-[20px] shadow-xl"
            >
              {categoryList.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    setCategoryName(item.title);
                    setIsCategoryFocused(false);
                    setCategorySlug(item.slug)
                  }}
                  className="text-black tracking-wider cursor-pointer hover:text-red-500"
                >
                  {item.title}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div
          onMouseEnter={() => setIsSearchBtnHovered(true)}
          onMouseLeave={() => setIsSearchBtnHovered(false)}
          className="w-max relative"
        >
          <button
            type="submit"
            style={{
              zIndex: "50",
            }}
            className={`${
              isSearchBtnHovered
                ? "text-darkPink bg-smoke"
                : "bg-white text-black"
            } px-[20px] py-[8px] rounded-[6px] ease duration-300`}
          >
            Search
          </button>
        </div>
      </form>

      {errorMessage && (
        <ErrorDialog
          mesage={errorMessage}
          handleCross={() => setErrorMessage("")}
        />
      )}

      {errorMessage && (
        <div
          style={{
            zIndex: 49,
          }}
          className="fixed w-full h-[100vh] top-0 left-0 right-0 bottom-0 bg-deepBlue opacity-[75%] "
        />
      )}
    </div>
  );
};

export default Search;
