export const quickLinks = [
    {
        name: "Home",
        slug: ""
    },
    {
        name: "Blogs",
        slug: "blogs"
    },
    {
        name: "Gallery",
        slug: "gallery"
    },
    {
        name: "Our Team",
        slug: "our-team"
    },
    {
        name: "Contact Us",
        slug: "contact-us"
    },
]