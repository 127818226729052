import React, { useEffect, useState } from "react";
import { mountain } from "../assets";
import { useNavigate, useParams } from "react-router-dom";
import axiosBaseURL from "../utils/apiBaseUrl";
import { ErrorDialog, Loader, SuccessDialog } from "../components";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";

const BookTrip = () => {
  let { slug } = useParams();
  const navigate = useNavigate();
  const recaptchaRef = React.createRef();

  // Handle Active Nav
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavState());
  }, []);

  const [tripDetails, setTripDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [nationality, setNationality] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [noOfAdults, setNoOfAdults] = useState("");
  const [noOfChilds, setNoOfChilds] = useState("");
  const [specialRequirements, setSpecialRequirements] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");

  const goBack = () => {
    navigate(-1);
  };

  const onChange = (value) => {
    setCaptchaValue(value);
  };

  const fetchTripDetails = async () => {
    if (slug) {
      try {
        setLoading(true);
        await axiosBaseURL.get(`/api/trip/${slug}`).then((res) => {
          if (res.data) {
            setLoading(false);
            setTripDetails(res.data);
          }
        });
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchTripDetails();
  }, []);

  //Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      name &&
      nationality &&
      email &&
      address &&
      departureDate &&
      arrivalDate &&
      noOfAdults &&
      noOfChilds
    ) {

      const formData = {
        name,
        country: nationality,
        email,
        address,
        departure_date: departureDate,
        arrival_date: arrivalDate,
        no_of_childs: noOfAdults,
        no_of_adults: noOfChilds,
        contact_no: phone,
        content: specialRequirements,
        group_size: "",
      };

      try {
        setIsSubmitting(true);
        await axiosBaseURL
          .post(`/api/book-trip/${slug}`, formData)
          .then((res) => {
            setIsSubmitting(false);
            setSuccessMessage(res.data.message);
          });
      } catch (error) {
        console.log(error);
        setIsSubmitting(false);
        setSuccessMessage("");
        setErrorMessage(error.message);
      }
    } else {
      setErrorMessage("Fill all the fields carefully!");
    }
  };

  // Handle Reset
  const handleReset = () => {
    setAddress("");
    setPhone("");
    setArrivalDate("");
    setName("");
    setDepartureDate("");
    setEmail("");
    setNationality("");
    setNoOfAdults("");
    setNoOfChilds("");
    setSpecialRequirements("");
  };

  return (
    <div className="bg-smoke">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Book Trip</title>
      </Helmet>

      {loading && <Loader />}

      {!loading && tripDetails?.model && (
        <div>
          {/*...Top Banner....*/}
          <div className="relative w-full h-[350px]">
            <img src={mountain} alt="" className="w-full h-full object-cover" />
            <div className="px-[20px] py-[10px] absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] bg-gradient-to-r from-white to-transparent text-center font-poppins">
              <p className="font-bold">Book Trip</p>
              {tripDetails?.model?.title && (
                <p className="text-xl font-bold text-deepBlue">
                  {tripDetails?.model?.title}
                </p>
              )}
            </div>
          </div>

          {/*....Form...*/}
          <div className="w-full flex flex-col items-center gap-[30px] font-poppins px-[20px] py-[70px]">
            <div className="text-center">
              <p className="text-xl font-bold text-deepBlue">
                {tripDetails?.model?.title ? (
                  tripDetails?.model?.title
                ) : (
                  <Loader />
                )}
              </p>
              <p className="font-deepBlue text-sm">
                Fill out the form below to book this trip.
              </p>
            </div>

            <form
              onSubmit={handleSubmit}
              className="w-full md:w-[660px] flex flex-col gap-[20px] text-deepBlue"
            >
              <label className="flex flex-col items-start gap-[5px]">
                <p className="">Enter your full name</p>
                <input
                  type="text"
                  placeholder="John Doe"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full border border-[#ABABAB] py-[9px] px-[10px] rounded-[4px] outline-none bg-transparent"
                />
              </label>

              <div className="flex flex-col md:flex-row items-center gap-[20px]">
                <label className="w-full md:w-[50%] flex flex-col items-start gap-[5px]">
                  <p className="">Address</p>
                  <input
                    type="text"
                    placeholder="Kathmandu-16, Nepal"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="w-full border border-[#ABABAB] py-[9px] px-[10px] rounded-[4px] outline-none bg-transparent"
                  />
                </label>

                <label className="w-full md:w-[50%]flex flex-col items-start gap-[5px]">
                  <p className="">Nationality</p>
                  <input
                    type="text"
                    placeholder="Eg. Romanian"
                    value={nationality}
                    onChange={(e) => setNationality(e.target.value)}
                    className="w-full border border-[#ABABAB] py-[9px] px-[10px] rounded-[4px] outline-none bg-transparent"
                  />
                </label>
              </div>

              <div className="flex flex-col md:flex-row items-center gap-[20px]">
                <label className="w-full md:w-[50%] flex flex-col items-start gap-[5px]">
                  <p className="">Email</p>
                  <input
                    type="email"
                    placeholder="johndoe@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full border border-[#ABABAB] py-[9px] px-[10px] rounded-[4px] outline-none bg-transparent"
                  />
                </label>

                <label className="w-full md:w-[50%] flex flex-col items-start gap-[5px]">
                  <p className="">Contact Number</p>
                  <input
                    type="text"
                    placeholder="+977 9876543235"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="w-full border border-[#ABABAB] py-[9px] px-[10px] rounded-[4px] outline-none bg-transparent"
                  />
                </label>
              </div>

              <div className="flex flex-col md:flex-row items-center gap-[20px]">
                <label className="w-full md:w-[50%] flex flex-col items-start gap-[5px]">
                  <p className="">Arrival Date</p>
                  <input
                    type="date"
                    placeholder="mm/dd/yyyy"
                    value={arrivalDate}
                    onChange={(e) => setArrivalDate(e.target.value)}
                    className="w-full border border-[#ABABAB] py-[9px] px-[10px] rounded-[4px] outline-none bg-transparent"
                  />
                </label>

                <label className="w-full md:w-[50%] flex flex-col items-start gap-[5px]">
                  <p className="">Departure Date</p>
                  <input
                    type="date"
                    placeholder="mm/dd/yyyy"
                    value={departureDate}
                    onChange={(e) => setDepartureDate(e.target.value)}
                    className="w-full border border-[#ABABAB] py-[9px] px-[10px] rounded-[4px] outline-none bg-transparent"
                  />
                </label>
              </div>

              <div className="flex flex-col md:flex-row items-center gap-[20px]">
                <label className="w-full md:w-[50%] flex flex-col items-start gap-[5px]">
                  <p className="">No. of Adults</p>
                  <input
                    type="number"
                    placeholder="1"
                    value={noOfAdults}
                    onChange={(e) => setNoOfAdults(e.target.value)}
                    className="w-full border border-[#ABABAB] py-[9px] px-[10px] rounded-[4px] outline-none bg-transparent"
                  />
                </label>

                <label className="w-full md:w-[50%] flex flex-col items-start gap-[5px]">
                  <p className="">No. of children</p>
                  <input
                    type="number"
                    placeholder="2"
                    value={noOfChilds}
                    onChange={(e) => setNoOfChilds(e.target.value)}
                    className="w-full border border-[#ABABAB] py-[9px] px-[10px] rounded-[4px] outline-none bg-transparent"
                  />
                </label>
              </div>

              <label className="w-full flex flex-col items-start gap-[5px]">
                <p className="">Special Requirement</p>
                <textarea
                  placeholder="Let us know all your inquiries and we will get back to you shortly"
                  value={specialRequirements}
                  onChange={(e) => setSpecialRequirements(e.target.value)}
                  className="w-full h-[100px] border border-[#ABABAB] py-[9px] px-[10px] rounded-[4px] outline-none bg-transparent"
                />
              </label>

              <div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6Lc-hDEmAAAAADwDSZf-KPw3MvqFjKpiSzjkK8IK"
                  onChange={onChange}
                />
              </div>

              <div className="w-full flex flex-col items-end gap-[10px]">
                <button onClick={() => handleReset()} className="text-darkPink">
                  Reset
                </button>
              </div>

              <div className="flex justify-between items-center">
                <div className="hidden md:flex items-center gap-[5px]">
                  <p className="text-[30px]">&#x2039;</p>
                  <p
                    onClick={() => goBack()}
                    className="underline cursor-pointer"
                  >
                    Go Back
                  </p>
                </div>

                {isSubmitting ? (
                  <div className="relative w-[150px] mt-[30px]">
                    <div className="glowing-sun"></div>
                    <p className="text-[18px] font-poppins absolute right-[0px] top-[50%] transform -translate-y-[50%] ">
                      Wait...
                    </p>
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="bg-darkPink w-[300px] py-[10px] text-white font-[500]"
                  >
                    Book This trip
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}

      {successMessage && (
        <SuccessDialog
          message={successMessage}
          handleCross={() => {
            setSuccessMessage("");
            handleReset();
          }}
        />
      )}

      {errorMessage && (
        <ErrorDialog
          mesage={errorMessage}
          handleCross={() => setErrorMessage("")}
        />
      )}

      {(successMessage || errorMessage) && (
        <div
          style={{
            zIndex: 49,
          }}
          className="fixed w-full h-[100vh] top-0 left-0 right-0 bottom-0 bg-deepBlue opacity-[75%] "
        />
      )}
    </div>
  );
};

export default BookTrip;
