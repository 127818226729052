import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { AboutUs, Album, BlogDetails, Blogs, BookTrip, ContactUs, DestinationTrip, GalleryImages, Home, OurTeam, PopularTrips, SearchPage, Testimonials, TripByDestination, TripDetails } from "./routes";
import axiosBaseURL from "./utils/apiBaseUrl";
import { Footer, Navbar } from "./components";
import PageNotFound from "./components/PageNotFound";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}

function App() {
  const [scrollingDown, setScrollingDown] = useState(false);
  const [siteInfo, setSiteInfo] = useState();
  // Hide and Show Navbar logic
  let lastScrollY = window.scrollY;

  useEffect(() => {
    const handleScroll = () => {
      if (lastScrollY < window.scrollY) {
        setScrollingDown(true);
      } else {
        setScrollingDown(false);
      }

      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchSiteInfo = async () => {
    try {
      await axiosBaseURL.get("/api/site_info").then((res) => {
        if (res.data.data) {
          setSiteInfo(res.data.data);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSiteInfo();
  }, []);

  return (
    <div>
      <ScrollToTop />
      <Navbar scrollingDown={scrollingDown} siteInfo={siteInfo} />
      <div className="w-full h-[75px] md:h-[135px]"></div>
      <Routes>
        <Route path="/" element={<Home video_url={siteInfo?.video_url} siteInfo={siteInfo} />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/popular-trips" element={<PopularTrips />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/book-trip/:slug" element={<BookTrip />} />
        <Route path="/destination/:slug" element={<DestinationTrip />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/gallery" element={<Album />} />
        <Route path="/gallery/:slug" element={<GalleryImages />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:slug" element={<BlogDetails siteInfo={siteInfo} />} />
        <Route path="/trip/:slug" element={<TripDetails />} />
        <Route path="/trip-by-destination" element={<TripByDestination />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer siteInfo={siteInfo} />
    </div>
  );
}

export default App;
