import React, { useEffect, useRef, useState } from "react";
import TestimonialCard from "./TestimonialCard";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";
import BlogCard from "./BlogCard";

const BlogSlider = ({ data }) => {
  const sliderRef = useRef(null);

  const [sliderData, setSliderData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [remainingItems, setRemainingItems] = useState([]);
  const [activeIndex, setActiveIndex] = useState(1);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [mobileDisplayData, setMobileDisplayData] = useState();
  const [dataIndex, setDataIndex] = useState(0);
  const [twoData, setTwoData] = useState([]);

  // Handling data on load
  useEffect(() => {
    if (data?.length > 0) {
      setSliderData(data);
      const array = [...data];

      if (array.length >= 3) {
        const takenData = array.splice(0, 3);
        setTwoData([data[0], data[1]]);
        setDisplayedData(takenData);
      }
      if (array.length > 0) {
        setRemainingItems(array);
      }
    }
  }, [data]);

  // For Mobile view
  useEffect(() => {
    if (sliderData?.length > 0) {
      setMobileDisplayData(sliderData[dataIndex]);
    }
  }, [sliderData, dataIndex]);

  const handleNextBtn = () => {
    if (dataIndex < sliderData?.length - 1) {
      setDataIndex((prev) => prev + 1);
    }
  };

  const handlePrevBtn = () => {
    if (dataIndex > 0) {
      setDataIndex((prev) => prev - 1);
    }
  };

  // Handle Next Click
  const handleNextClick = () => {
    setButtonClicked(false);
    setButtonClicked(true);
    setTwoData([data[2]]);
    const array = [...remainingItems];
    if (array.length < 1) {
      return;
    }
    if (array.length >= 3) {
      const takenData = array.splice(0, 3);
      setDisplayedData(takenData);
    } else if (array.length < 3 && array.length > 0) {
      setDisplayedData(array);
    }
    if (array.length > 0) {
      setRemainingItems(array);
    }
  };

  // Handle Prev Click
  const handlePrevClick = () => {
    setButtonClicked(false);
    setButtonClicked(true);
    setTwoData([data[0], data[1]]);
    if (displayedData?.length > 0) {
      const index = data.indexOf(displayedData[0]);
      if (index > 2) {
        const newData = [data[index - 3], data[index - 2], data[index - 1]];
        setDisplayedData(newData);
      } else {
        setDisplayedData([data[0], data[1], data[2]]);
      }
    }
  };

  return (
    <div className="w-full">
      {/*...Three Data Slider...*/}
      <div
        id="blog-slider1"
        className="relative w-full flex justify-center md:px-[30px]"
      >
        <div
          ref={sliderRef}
          className={`relative hidden  w-full lg:flex items-center justify-center gap-[70px] md:gap-[20px] lg:gap-[50px]`}
          style={{
            transition: "all 0.3s ease-in-out",
          }}
        >
          {displayedData?.length > 0 &&
            displayedData.map((item, index) => (
              <BlogCard key={index} data={item} />
            ))}
        </div>

        <div
          className="hidden z-20 absolute left-0 top-[50%] transform -translate-y-[50%] bg-white w-[50px] h-[50px] rounded-full lg:flex justify-center items-center text-black text-[30px] font-bold shadow-xl cursor-pointer"
          onClick={handlePrevClick}
        >
          <BsArrowLeftShort />
        </div>
        <div
          className="hidden z-20 absolute right-0 top-[50%] transform -translate-y-[50%] bg-white w-[50px] h-[50px] rounded-full lg:flex justify-center items-center text-black text-[30px] font-bold shadow-xl cursor-pointer"
          onClick={handleNextClick}
        >
          <BsArrowRightShort />
        </div>
      </div>

      {/*....Show two data slider..*/}
      <div
        id="blog-slider2"
        className="relative w-full flex justify-center md:px-[30px]"
      >
        <div
          ref={sliderRef}
          className={`relative hidden  w-full lg:flex items-center justify-center gap-[70px] md:gap-[20px] lg:gap-[50px]`}
          style={{
            transition: "all 0.3s ease-in-out",
          }}
        >
          {twoData?.length > 0 &&
            twoData.map((item, index) => <BlogCard key={index} data={item} />)}
        </div>

        <div
          className="hidden z-20 absolute left-0 top-[50%] transform -translate-y-[50%] bg-white w-[50px] h-[50px] rounded-full lg:flex justify-center items-center text-black text-[30px] font-bold shadow-xl cursor-pointer"
          onClick={handlePrevClick}
        >
          <BsArrowLeftShort />
        </div>
        <div
          className="hidden z-20 absolute right-0 top-[50%] transform -translate-y-[50%] bg-white w-[50px] h-[50px] rounded-full lg:flex justify-center items-center text-black text-[30px] font-bold shadow-xl cursor-pointer"
          onClick={handleNextClick}
        >
          <BsArrowRightShort />
        </div>
      </div>

      {/*...For Mobile View...*/}
      {mobileDisplayData && (
        <div className="relative w-full flex justify-center px-[70px]">
          <div
            className={`relative w-full flex items-center justify-center gap-[20px] md:gap-[70px] lg:hidden`}
            style={{
              transition: "all 0.3s ease-in-out",
            }}
          >
            <BlogCard data={mobileDisplayData} />
          </div>

          <div
            className="lg:hidden z-20 absolute left-0 top-[50%] transform -translate-y-[50%] bg-white w-[50px] h-[50px] rounded-full flex justify-center items-center text-black text-[30px] font-bold shadow-xl cursor-pointer"
            onClick={handlePrevBtn}
          >
            <BsArrowLeftShort />
          </div>
          <div
            className="lg:hidden z-20 absolute right-0 top-[50%] transform -translate-y-[50%] bg-white w-[50px] h-[50px] rounded-full flex justify-center items-center text-black text-[30px] font-bold shadow-xl cursor-pointer"
            onClick={handleNextBtn}
          >
            <BsArrowRightShort />
          </div>
        </div>
      )}

      <div className="w-full text-center mt-[30px]">
        <button className="text-smoke italic underline underline-offset-8 text-lg font-bold decoration-2 decoration-deepBlue">
          <Link to="/blogs">Show All</Link>
        </button>
      </div>
    </div>
  );
};

export default BlogSlider;
