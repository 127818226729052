export const navLinks = [
    {
        name: "Home",
        slug: "",
        id: 0
    },
    {
        name: "Contact Us",
        slug: "contact-us",
        id: 5
    },
    {
        name: "About Us",
        slug: "about-us",
        id: 5
    },
    {
        name: "Gallery",
        slug: "gallery",
        id: 2
    },
    {
        name: "Blogs",
        slug: "blogs",
        id: 3
    },
    {
        name: "Our Team",
        slug: "our-team",
        id: 4
    },
]