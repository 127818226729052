import React, { useEffect, useState } from "react";
import axiosBaseURL from "../utils/apiBaseUrl";
import { Loader } from "../components";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";

const Testimonials = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavState());
  }, []);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [contentId, setContentId] = useState();

  // fETCH tESTIMONIALS
  const fetchTestimonials = async () => {
    setLoading(true);
    try {
      await axiosBaseURL.get("/api/testimonials").then((res) => {
        setLoading(false);
        if (res.data.data.length > 0) {
          setData(res.data.data);
        }
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  return (
    <div className="bg-smoke p-[20px]">
      {loading && <Loader />}
      <div className="w-full flex justify-center mt-[30px]">
        <div className="w-max bg-deepBlue px-[30px] py-[8px] rounded-[4px]">
          <p className="font-poppins text-[20px] font-black text-[#E1EFFF] tracking-wider">
            Testimonials
          </p>
        </div>
      </div>

      <div className="w-full flex items-start justify-center gap-[40px] flex-wrap mt-[50px]">
        {data?.length > 0 &&
          data.map((item, index) => (
            <div
              key={index}
              className={`max-w-[300px] min-w-[300px] ${
                contentId !== index && "h-[360px]"
              } bg-white flex flex-col justify-between gap-[10px] font-poppins p-[10px] rounded-lg shadow-lg cursor-pointer transition transform duration-500 ease-out`}
            >
              <div className="flex-inline">
                <div
                  className="grow flex flex-col justify-center gap-[6px] text-sm text-[#021327]"
                  dangerouslySetInnerHTML={{
                    __html:
                      contentId === index
                        ? item.content
                        : item?.content.length > 400
                        ? item.content.slice(0, 400) + "..."
                        : item.content,
                  }}
                />
                {item?.content.length > 400 && contentId !== index && (
                  <p
                    className="text-darkPink"
                    onClick={() => setContentId(index)}
                  >
                    Read more
                  </p>
                )}

                {contentId === index && (
                  <p className="text-darkPink" onClick={() => setContentId()}>
                    Show less
                  </p>
                )}
              </div>

              <div className="flex items-center gap-[10px]">
                <img
                  src={item?.image}
                  alt=""
                  className="w-[50px] h-[50px] rounded-full"
                />
                <div>
                  <p className="font-bold text-[#021327]">{item.name}</p>
                  <p className="text-[#021327]">{item.country}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Testimonials;
