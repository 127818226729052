import React from "react";
import Rating from "./Rating";

const ReviewCard = ({ data }) => {
  const { image_url, name, rate, reviews } = data;

  return (
    <div className="w-[300px] flex flex-col gap-[8px] rounded-[5px] bg-white p-[10px] shadow-lg">
      <div className="flex items-center gap-[7px]">
        <img
          src={image_url}
          alt=""
          className="w-[60px] h-[60px] object-cover rounded-full"
        />
        <div>
          <p className="text-deepBlue font-bold">{name}</p>
        </div>
      </div>

      <div>
        <Rating rating={rate} />
      </div>
      <div>
        <p className="text-justify">{reviews}</p>
      </div>
    </div>
  );
};

export default ReviewCard;
