import React from "react";
import { Link } from "react-router-dom";

const CategoryCard = ({ data }) => {
  return (
    <Link to={`/destination/${data?.slug}`} className="min-w-[170px] max-w-[170px] flex flex-col items-center gap-[10px] text-center">
      <img src={data.image_url} alt="" className="w-[150px] h-[220px] rounded-full object-cover" />

      <div className="w-full">
        <p className="text-darkPink font-poppins font-bold">{data?.title}</p>
      </div>
    </Link>
  );
};

export default CategoryCard;
