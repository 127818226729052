import React from "react";
import { RxCross2 } from "react-icons/rx";

const ImageView = ({ image, handleCross }) => {
  return (
    <div style={{
        zIndex:100
    }} className="w-[95%] md:w-[80%] fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]">
      <img src={image} alt="" className="w-full object-contain rounded-xl" />

      <div
        onClick={handleCross}
        className="bg-smoke cursor-pointer absolute top-[20px] right-[20px] w-[30px] h-[30px] rounded-full shadow-lg flex justify-center items-center"
      >
        <RxCross2 className="text-xl text-deepBlue" />
      </div>
    </div>
  );
};

export default ImageView;
