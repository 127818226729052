import React, { useEffect, useState } from "react";
import { location2 } from "../assets";
import { Filter, FilterMobile, Loader, PackageCard } from "../components";
import { BsPauseFill } from "react-icons/bs";
import { trekkingPackages } from "../data/trekkingPackage";
import { useSearchParams } from "react-router-dom";
import axiosBaseURL from "../utils/apiBaseUrl";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";
import { Helmet } from "react-helmet";

const SearchPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  // Handle Active Nav
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavState());
  }, []);

  const destination = searchParams.get("destination");
  const days = searchParams.get("days");
  const category = searchParams.get("category");
  const difficulty = searchParams.get("difficulty");

  const [landscapeMode, setLandscapeMode] = useState(false);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [pagesNumbers, setPagesNumbers] = useState([]);
  const [activePageId, setActivePageId] = useState();
  const [filtering, setFiltering] = useState(false);

  // fETCH Data
  const fetchData = async (page) => {
    setLoading(true);
    if (page) {
      try {
        await axiosBaseURL
          .get(
            `/api/search?destination=${destination}&duration=${
              days ? days : ""
            }&category=${category ? category : ""}&trip_grade=${
              difficulty ? difficulty : ""
            }&page=${page}`
          )
          .then((res) => {
            setLoading(false);
            if (res.data?.trips?.data?.length > 0) {
              setData(res.data.trips.data);
              setTotalPages(res.data.trips.last_page);
              setActivePageId(res.data.trips.current_page);
            }
          });
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    } else {
      try {
        await axiosBaseURL
          .get(
            `/api/search?destination=${destination}&duration=${
              days ? days : ""
            }&category=${category ? category : ""}&trip_grade=${
              difficulty ? difficulty : ""
            }`
          )
          .then((res) => {
            setLoading(false);
            if (res.data?.trips?.data?.length > 0) {
              setData(res.data.trips.data);
              setTotalPages(res.data.trips.last_page);
              setActivePageId(res.data.trips.current_page);
            }
          });
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  useEffect(() => {
    if (loading || filtering) {
      window.scrollTo(0, 0);
    }
  }, [loading, filtering]);

  // Handling pagination
  useEffect(() => {
    if (totalPages > 0) {
      let arr = [];
      for (let i = 0; i < totalPages; i++) {
        arr.push(i + 1);
      }
      setPagesNumbers(arr);
    }
  }, [totalPages]);

  // Handle Filter
  const handleFilter = async (
    moneyRanges,
    difficultyLevel,
    days,
    accomodationArray
  ) => {

    try {
      setFiltering(true);
      await axiosBaseURL
        .get(
          `/api/filter/trip?trip_grade=${difficultyLevel}&duration=${days}&accommodation=${accomodationArray}&price=${moneyRanges}`
        )
        .then((res) => {
          setFiltering(false);
          if (res.data?.trips?.data?.length > 0) {
            setData(res.data.trips.data);
            setTotalPages(res.data.trips.last_page);
            setActivePageId(res.data.trips.current_page);
          }
        });
    } catch (err) {
      setFiltering(false);
      console.log(err);
    }
  };

  return (
    <div className="bg-smoke font-poppins flex flex-col items-start px-[20px] md:px-[70px] py-[40px]">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search Result for {destination ? destination : ""}</title>
      </Helmet>
      <div className="w-full flex justify-center">{loading && <Loader />}</div>

      {!loading && data?.length > 0 ? (
        <div className="w-full">
          {destination && (
            <div className="w-full flex justify-center items-center flex-wrap gap-[20px]">
              <p className="font-bold text-darkPink">Search result for</p>

              <div className="border border-deepBlue border-b-2 border-t-0 border-l-0 border-r-0 flex items-center gap-[5px] px-[10px] py-[5px]">
                <img src={location2} alt="" className="w-[20px] h-[20px]" />
                <p>{destination}</p>
              </div>
            </div>
          )}

          <div className="w-full flex items-start justify-center md:justify-between mt-[30px]">
            {/*...Advanced Filter..*/}
            <div className="w-max md:w-[300px] flex flex-col gap-[10px]">
              <p className="hidden lg:flex font-bold">Advanced Search</p>
              {/*...For Mobile...*/}
              <button
                onClick={() => setDisplayFilter(true)}
                className="lg:hidden w-max bg-darkPink text-smoke px-[20px] py-[8px] rounded-[25px]"
              >
                Advanced Search
              </button>
            </div>

            <div>
              <div className="w-full hidden md:flex justify-end">
                <div className="bg-white rounded-full px-[10px] py-[5px] flex items-center font-bold text-[25px]">
                  <BsPauseFill
                    onClick={() => setLandscapeMode(true)}
                    className={`cursor-pointer ${
                      landscapeMode ? "text-darkPink" : "text-smoke"
                    } transform rotate-90 border border-deepBlue border-r-0 border-t-1 border-l-0 border-b-0`}
                  />
                  <BsPauseFill
                    onClick={() => setLandscapeMode(false)}
                    className={`cursor-pointer ${
                      !landscapeMode ? "text-darkPink" : "text-smoke"
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className={`w-full flex ${
              data ? "justify-between " : "justify-start"
            } gap-[30px]`}
          >
            <div className="hidden lg:block flex min-w-[300px] max-w-[300px]">
              <Filter handleFilter={handleFilter} />
            </div>

            {/*...Package Listing....*/}
            <div className="w-full flex justify-start items-start">
              {filtering ? (
                <div className="grow text-center">
                  <p className="text-deepBlue font-bold">Loading...</p>
                </div>
              ) : (
                <div className="w-full grow flex flex-col md:flex-row items-center md:justify-center items-center flex-wrap gap-[40px] mt-[20px]">
                  {data?.map((item, index) => (
                    <PackageCard
                      key={index}
                      data={item}
                      inLandscape={landscapeMode}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>

          {/*...Pagination...*/}
          <div className="w-full flex items-center justify-center flex-wrap gap-[20px] py-[30px] font-poppins">
            {totalPages > 0 &&
              pagesNumbers.length > 0 &&
              pagesNumbers.map((item, index) => (
                <div
                  key={index}
                  className={`${
                    activePageId === item
                      ? "bg-darkPink text-white"
                      : "bg-transparent text-deepBlue"
                  } w-[30px] h-[30px] rounded-full flex justify-center items-center border border-darkPink cursor-pointer hover:bg-darkPink hover:text-white`}
                  onClick={() => fetchData(item)}
                >
                  <p>{item}</p>
                </div>
              ))}
          </div>
        </div>
      ) : !loading && data?.length < 1 ? (
        <div className="w-full h-[80vh] flex flex-col justify-center items-center font-bold text-deepBlue text-[25px]">
          <p className="font-bold trext-[29px] text-darkPink">Sorry!</p>
          <p>No Data Found</p>
        </div>
      ) : (
        ""
      )}

      {/*...Display Filter in mobile view....*/}
      {displayFilter && (
        <div className="w-[95%] lg:hidden">
          <FilterMobile
            handleFilter={handleFilter}
            handleCross={() => setDisplayFilter(false)}
            isActive={displayFilter}
          />
        </div>
      )}

      {/*...Overlay....*/}
      {displayFilter && (
        <div
          style={{
            zIndex: "50",
          }}
          className="lg:hidden bg-black opacity-[60%] z-10 fixed top-0 left-0 right-0 bottom-0 w-full h-[100vh]"
        ></div>
      )}
    </div>
  );
};

export default SearchPage;
