import React, { useState } from "react";
import { brand1, brand2, brand3, call, location3, logo, mail } from "../assets";
import { quickLinks } from "../data/footerData";
import { BsTwitter } from "react-icons/bs";
import { BiLogoFacebook, BiLogoInstagram } from "react-icons/bi";
import { TfiYoutube } from "react-icons/tfi";
import { ImSkype, ImWhatsapp } from "react-icons/im";
import { Link } from "react-router-dom";
import axiosBaseURL from "../utils/apiBaseUrl";
import SuccessDialog from "./SuccessDialog";
import ErrorDialog from "./ErrorDialog";

const Footer = ({ siteInfo }) => {
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Handle Newsletter subscribe
  const handleSubscribe = async () => {
    const email = subscribeEmail;
    try {
      setLoading(true);
      await axiosBaseURL.post("/api/subscriber", { email }).then((res) => {
        setSuccessMessage(res.data.message);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSuccessMessage("");
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="w-full bg-darkPink">
      <div className="w-full bg-darkPink flex flex-col gap-[50px] md:grid md:grid-cols-4 md:gap-4 text-white px-[70px] py-[40px] font-poppins text-sm">
        {/*..First Section....*/}
        <div className="flex flex-col items-center lg:items-start text-center lg:text-start gap-[10px]">
          <div className="bg-smoke p-[10px] rounded-[4px]">
            <img src={logo} alt="logo" className="w-full object-contain" />
          </div>

          <div className="flex items-center gap-[5px]">
            <img src={location3} alt="" className="w-[15px] w-[15px]" />
            <p>{siteInfo?.address}</p>
          </div>

          <div className="flex items-center gap-[5px]">
            <img src={call} alt="" className="w-[15px] w-[15px]" />
            <p>
              {siteInfo?.mobile_no} , {siteInfo?.mobile_no_2}
            </p>
          </div>

          <Link
            to={`mailto:${siteInfo?.email}`}
            className="flex items-center gap-[5px]"
          >
            <img src={mail} alt="" className="w-[15px] w-[15px]" />
            <p>{siteInfo?.email}</p>
          </Link>
        </div>
        {/*..Quick Links....*/}
        <div className="w-full flex flex-col items-center gap-[20px] text-center">
          <p className="font-bold tracking-wider text-[17px]">Quick Links</p>
          <ul className="flex flex-col gap-[10px]">
            {quickLinks?.map((item, index) => (
              <li key={index}>
                <Link to={`/${item.slug}`}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        {/*..Associated With....*/}
        <div className="w-full flex flex-col items-center gap-[20px] text-center">
          <p className="font-bold tracking-wider text-[17px]">
            Associated With:
          </p>
          <div className="flex justify-center items-center flex-wrap gap-[15px]">
            <img
              src={brand1}
              alt=""
              className="w-[70px] h-[70px] rounded-[4px] object-contain"
            />
            <img
              src={brand2}
              alt=""
              className="w-[70px] h-[70px] rounded-[4px] object-contain"
            />
            <img
              src={brand3}
              alt=""
              className="w-[70px] h-[70px] rounded-[4px] object-contain"
            />
          </div>
        </div>
        {/*..Subscribe Now....*/}
        <div className="w-full flex flex-col items-center gap-[20px] text-center">
          <p className="font-bold tracking-wider text-[17px]">Subscribe Now</p>
          <div className="flex flex-col gap-[20px]">
            <form
              onSubmit={handleSubscribe}
              className="flex items-center gap-[5px] border border-b-smoke border-t-0 border-l-0 border-r-0 p-[5px]"
            >
              <img src={mail} alt="" className="w-[15px] w-[15px]" />
              <input
                type="email"
                placeholder="Enter your email"
                value={subscribeEmail}
                onChange={(e) => setSubscribeEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevents the default form submission
                    handleSubscribe(); // Calls the submit method explicitly
                  }
                }}
                className="bg-transparent placeholder:text-smoke outline-none"
              />
              {loading && (
                <div>
                  <p>Wait...</p>
                </div>
              )}
            </form>

            {/*..Follow us..*/}
            <div className="flex flex-col gap-[20px]">
              <p className="font-bold text-[15px] tracking-wider">Follow Us</p>
              <div className="flex items-center gap-[20px] text-[25px] font-bold text-white">
                <Link to={siteInfo?.facebook} target="_blank">
                  <BiLogoFacebook />
                </Link>

                <Link to={siteInfo?.twitter} target="_blank">
                  <BsTwitter />
                </Link>

                <Link to={siteInfo?.instagram} target="_blank">
                  <BiLogoInstagram />
                </Link>

                <Link to={siteInfo?.youtube} target="_blank">
                  <TfiYoutube />
                </Link>

                <Link to={siteInfo?.skype} target="_blank">
                  <ImSkype />
                </Link>

                <Link to={siteInfo?.whatsapp} target="_blank">
                  <ImWhatsapp />
                </Link>
              </div>
            </div>
          </div>
        </div>

        {successMessage && (
          <SuccessDialog
            message={successMessage}
            handleCross={() => {
              setSuccessMessage("");
              setSubscribeEmail("");
            }}
          />
        )}

        {errorMessage && (
          <ErrorDialog
            mesage={errorMessage}
            handleCross={() => setErrorMessage("")}
          />
        )}

        {(successMessage || errorMessage) && (
          <div
            style={{
              zIndex: 49,
            }}
            className="fixed w-full h-[100vh] top-0 left-0 right-0 bottom-0 bg-deepBlue opacity-[75%] "
          />
        )}
      </div>

      <div className="bg-red-700 w-full text-center px-[10px] py-[10px] text-center text-white font-poppins">
        <p className="w-full">
         # Developed and designed by{" "}
          <Link
            to="https://onvirotech.com/"
            target="_blank"
            className="underline decoration-white underline-offset-4"
          >
            Onviro Tech P. Ltd
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Footer;
