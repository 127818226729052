import React, { useEffect, useState } from "react";
import axiosBaseURL from "../utils/apiBaseUrl";
import { useParams } from "react-router-dom";
import {
  DateAndPrice,
  Includes,
  Iteneraries,
  Loader,
  MainImage,
  Review,
  TripOverview,
} from "../components";
import { reviewData } from "../data/reviewData";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";
import { Helmet } from "react-helmet";
import Book from "../components/Book";

const selectionTab = [
  {
    name: "Trip Overview",
    id: 1,
  },
  {
    name: "Itineraries",
    id: 2,
  },
  {
    name: "Includes",
    id: 3,
  },
  {
    name: "Date & Price",
    id: 4,
  },
  {
    name: "Book",
    id: 5,
  },
];

const TripDetails = () => {
  let { slug } = useParams();

  // Handle Active Nav
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavState());
  }, []);

  const [details, setDetails] = useState();
  const [hoveredTabId, setHoveredTabId] = useState();
  const [activeTabId, setActiveTabId] = useState(1);
  const [loading, setLoading] = useState(false);
  const [mainImageUrl, setMainImageUrl] = useState("")

  const fetchTripDetails = async () => {
    if (slug) {
      setLoading(true);
      try {
        await axiosBaseURL.get(`/api/trip/${slug}`).then((res) => {
          if (res.data) {
            setLoading(false);
            setDetails(res.data);
          }
        });
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  useEffect(() => {
    fetchTripDetails();
  }, [slug]);

  return (
    <div className="bg-smoke">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {details?.model?.seo_title ? details?.model?.seo_title : ""}
        </title>
        <meta
          name="description"
          content={
            details?.model?.seo_description
              ? details?.model?.seo_description
              : ""
          }
        />
      </Helmet>
      {!loading && details?.model?.image_url && details?.model?.title && (
        <div className="relative w-full md:h-[70vh] overflow-hidden">
          <MainImage
            image={mainImageUrl ? mainImageUrl : details.model.image_url}
            title={details.model.title}
          />
        </div>
      )}

       {/*...Gallery Images...*/}
       {details?.model?.gallery?.length > 0 && (
          <div className="w-full flex items-center justify-start overflow-x-auto gap-[10px] px-[20px] md:px-[70px] py-[30px]">
            {details?.model?.gallery.map((item, index) => (
              <div key={index} className="cursor-pointer" onClick={()=> setMainImageUrl(item.image_url)}>
                <img src={item.image_url} alt="" className="min-w-[80px] max-w-[80px] max-h-[70px] min-h-[70px] md:min-w-[100px] md:max-w-[100px] md:max-h-[80px] md:min-h-[80px] object-cover rounded-[5px]" />
              </div>
            ))}
          </div>
        )}

      <div className="px-[20px] md:px-[70px]">
        <div>
          {/*...Selection Tab..*/}
          <div className="border border-darkPink border-t border-b border-l-0 border-r-0 flex flex-col md:flex-row items-center justify-between">
            {selectionTab?.length > 0 &&
              selectionTab.map((item, index) => (
                <div
                  key={index}
                  onMouseEnter={() => setHoveredTabId(item.id)}
                  onMouseLeave={() => setHoveredTabId()}
                  onClick={() => setActiveTabId(item.id)}
                  className={`${
                    activeTabId === item.id || hoveredTabId === item.id
                      ? "bg-darkPink text-white"
                      : ""
                  } grow text-center py-[8px] px-[15px] font-poppins font-bold`}
                >
                  <button>{item.name}</button>
                </div>
              ))}
          </div>
          {/*...Show Loader...*/}
          {loading && !details && <Loader />}
          {/*..Active Content...*/}
          {activeTabId === 1 ? (
            <TripOverview info={details?.model} />
          ) : activeTabId === 2 ? (
            <Iteneraries info={details?.model?.itinerary} />
          ) : activeTabId === 3 ? (
            <Includes info={details?.model?.inclusion} />
          ) : activeTabId === 4 ? (
            <DateAndPrice data={details?.model?.exclusion} />
          ) : activeTabId === 5 ? (
            <Book tripDetails={details} />
          ) : (
            <TripOverview info={details?.model} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TripDetails;
