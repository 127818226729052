export const priceRange = [
  {
    range: "150 - 200",
    id: "150-200",
  },
  {
    range: "201 - 250",
    id: "201-250",
  },
  {
    range: "251 - 400",
    id: "251-400",
  },
  {
    range: "400+",
    id: "400%2B",
  },
];

export const noOfDays = [
  {
    range: "1-5 days",
    id: "1-5",
  },
  {
    range: "6-10 days",
    id: "6-10",
  },
  {
    range: "11-15 days",
    id: "11-15",
  },
  {
    range: "16-20 days",
    id: "16-20",
  },
  {
    range: "21-25 days",
    id: "21-25",
  },
  {
    range: "25+ days",
    id: "25%2B",
  },
];

export const accomodation = [
  {
    name: "Hotel",
    id: "hotel",
  },
  {
    name: "Lodge",
    id: "lodge",
  },
  {
    name: "Homestay",
    id: "homestay",
  },
  {
    name: "Guesthouse",
    id: "guesthouse",
  },
  {
    name: "Teahouse",
    id: "teahouse",
  },
  {
    name: "Others",
    id: "others",
  },
];

export const tripDifficulty = [
  {
    name: "Easy",
    id: "easy",
  },
  {
    name: "Moderate",
    id: "moderate",
  },
  {
    name: "Hard",
    id: "hard",
  },
];
