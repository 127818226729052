import React, { useEffect, useState } from "react";
import { ContactCard, ErrorDialog, SuccessDialog } from "../components";
import axiosBaseURL from "../utils/apiBaseUrl";
import { useDispatch } from "react-redux";
import { changeNavState } from "../reduxStore/navSlice";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = () => {
  const recaptchaRef = React.createRef();

  // Handle Active Nav
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavState(5));
  }, []);

  const contactInfo = {
    location: "Kathmandu-3200, Nepal",
    phone: "+97715203401 , +9779851002789 , +9779808137941",
    email: "redreachhimalaya@gmail.com",
  };

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [from, setFrom] = useState();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");

  const onChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (from && email && phone && subject && content && captchaValue) {
      const formData = {
        from,
        email,
        phone,
        subject,
        content,
        name: from,
      };

      try {
        setLoading(true);
        await axiosBaseURL
          .post("/api/contact-us/mail", formData)
          .then((res) => {
            setSuccessMessage(res.data.message);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
        setSuccessMessage("");
        setErrorMessage(error.message);
      }
    }
  };

  // Handle Reset
  const handleReset = () => {
    setFrom("");
    setPhone("");
    setEmail("");
    setSubject("");
    setContent("");
  };

  return (
    <div className="bg-smoke">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
      </Helmet>
      <div className="w-full bg-deepBlue flex flex-col items-center gap-[50px] text-white font-poppins p-[20px] md:p-[50px]">
        <div>
          <p className="font-poppins text-[40px] font-black text-[#E1EFFF] tracking-wider">
            CONTACT US
          </p>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row justify-center items-center gap-[40px] p-[20px]">
        {/*...map...*/}
        <div className="w-full md:w-[50%] flex justify-center">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14135.663428491045!2d85.3463187!3d27.6580749!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb17ce158fed69%3A0xba12d41025573606!2sRed%20Reach%20Himalaya%20Treks%20P.%20Ltd!5e0!3m2!1sen!2snp!4v1687928935838!5m2!1sen!2snp"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="w-full h-[350px] lg:h-[450px]"
          ></iframe>
        </div>
        {/*...Contact Form...*/}
        <div className="px-[20px] w-full md:w-[700px] flex flex-col items-center gap-[30px] py-[40px]">
          <p className="text-deepBlue font-bold text-lg font-poppins">
            Send us a message
          </p>

          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col gap-[25px]"
          >
            <div className="flex flex-col md:flex-row items-center gap-[30px]">
              <input
                type="text"
                placeholder="Your Name"
                onChange={(e) => setFrom(e.target.value)}
                className="w-full md:w-[50%] border border-deepBlue px-[10px] py-[9px] outline-none bg-transparent rounded-[4px] placeholder:text-deepBlue font-poppins"
              />
              <input
                type="email"
                placeholder="example@email.com"
                onChange={(e) => setEmail(e.target.value)}
                className="w-full md:w-[50%] border border-deepBlue px-[10px] py-[9px] outline-none bg-transparent rounded-[4px] placeholder:text-deepBlue font-poppins"
              />
            </div>

            <input
              type="text"
              pattern="[+-]?\d+"
              placeholder="Contact Number"
              onChange={(e) => setPhone(e.target.value)}
              className="border border-deepBlue px-[10px] py-[9px] outline-none bg-transparent rounded-[4px] placeholder:text-deepBlue font-poppins"
            />

            <input
              type="text"
              placeholder="Your Message Subject"
              onChange={(e) => setSubject(e.target.value)}
              className="border border-deepBlue px-[10px] py-[9px] outline-none bg-transparent rounded-[4px] placeholder:text-deepBlue font-poppins"
            />

            <textarea
              placeholder="Your Message Here"
              onChange={(e) => setContent(e.target.value)}
              className="h-[100px] border border-deepBlue px-[10px] py-[9px] outline-none bg-transparent rounded-[4px] placeholder:text-deepBlue font-poppins"
            />

            <div>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Lc-hDEmAAAAADwDSZf-KPw3MvqFjKpiSzjkK8IK"
                onChange={onChange}
              />
            </div>

            <div className="w-full flex justify-center">
              {loading ? (
                <div className="relative w-[150px] mt-[30px]">
                  <div className="glowing-sun"></div>
                  <p className="text-[18px] font-poppins absolute right-[0px] top-[50%] transform -translate-y-[50%] ">
                    Wait...
                  </p>
                </div>
              ) : (
                <button
                  type="submit"
                  className="w-[190px] bg-darkPink text-white px-[20px] py-[9px] font-poppins rounded-[4px] hover:opacity-[75%]"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row justify-center items-center gap-[40px] lg:gap-[70px] text-white font-poppins">
        {/*...Contact card...*/}
        <div className="flex flex-col gap-[20px]">
          <p className="text-smoke text-lg font-medium">
            You can reach us through -
          </p>

          <div>
            <ContactCard data={contactInfo} />
          </div>

          <p className="text-smoke text-lg font-[300]">
            or, fill up the form below.
          </p>
        </div>
      </div>

      {successMessage && (
        <SuccessDialog
          message={successMessage}
          handleCross={() => {
            setSuccessMessage("");
            handleReset();
          }}
        />
      )}

      {errorMessage && (
        <ErrorDialog
          mesage={errorMessage}
          handleCross={() => setErrorMessage("")}
        />
      )}

      {(successMessage || errorMessage) && (
        <div
          style={{
            zIndex: 49,
          }}
          className="fixed w-full h-[100vh] top-0 left-0 right-0 bottom-0 bg-deepBlue opacity-[75%] "
        />
      )}
    </div>
  );
};

export default ContactUs;
