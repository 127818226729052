import React, { useEffect, useState } from "react";
import axiosBaseURL from "../utils/apiBaseUrl";
import { BlogCard, Loader } from "../components";
import { changeNavState } from "../reduxStore/navSlice";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

const Blogs = () => {
  // Handle Active Nav
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavState(3));
  }, []);

  const [loading, setLoading] = useState(false);
  const [blogsData, setBlogsData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [pagesNumbers, setPagesNumbers] = useState([]);
  const [activePageId, setActivePageId] = useState();

  // fETCH Blogs
  const fetchBlogs = async (pageNumber) => {
    setLoading(true);
    setBlogsData([]);
    try {
      await axiosBaseURL
        .get(`/api/blog/list?page=${pageNumber}`)
        .then((res) => {
          if (res?.data?.data?.data?.length > 0) {
            setLoading(false);
            setBlogsData(res.data.data.data);
            setTotalPages(res.data.data.last_page);
            setActivePageId(res.data.data.current_page);
          }
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    if (loading) {
      window.scrollTo(0, 0);
    }
  }, [loading]);

  // Handling pagination
  useEffect(() => {
    if (totalPages > 0) {
      let arr = [];
      for (let i = 0; i < totalPages; i++) {
        arr.push(i + 1);
      }
      setPagesNumbers(arr);
    }
  }, [totalPages]);

  return (
    <div className="bg-smoke py-[60px] px-[40px]">
       <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs</title>
      </Helmet>
      <p className="font-poppins text-center text-[40px] font-black text-deepBlue tracking-wider">
        Blogs
      </p>

      {/*...Loader....*/}
      {loading && blogsData?.length < 1 && <Loader />}

      {/*...Blogs...*/}
      <div className="w-full mt-[50px] w-[90%] flex items-center justify-center flex-wrap gap-[50px]">
        {!loading && blogsData?.length > 0 ? (
          blogsData.map((item, index) => <BlogCard key={index} data={item} />)
        ) : !loading && blogsData?.length < 1 ? (
          <div className="w-full h-[80vh] flex flex-col justify-center items-center font-bold text-deepBlue text-[25px]">
            <p className="font-bold trext-[29px] text-darkPink">Sorry!</p>
            <p>No Data Found</p>
          </div>
        ) : (
          <div className="w-full h-[80vh] flex flex-col justify-center items-center font-bold text-deepBlue text-[25px]">
            <p className="font-bold trext-[29px] text-darkPink">Oops!</p>
            <p>Something Went Wrong</p>
          </div>
        )}
      </div>

      {/*...Pagination...*/}
      <div className="w-full flex items-center justify-center flex-wrap gap-[20px] py-[30px] font-poppins">
        {totalPages > 0 &&
          pagesNumbers.length > 0 &&
          pagesNumbers.map((item, index) => (
            <div
              key={index}
              className={`${
                activePageId === item
                  ? "bg-darkPink text-white"
                  : "bg-transparent text-deepBlue"
              } w-[30px] h-[30px] rounded-full flex justify-center items-center border border-darkPink cursor-pointer hover:bg-darkPink hover:text-white`}
              onClick={() => fetchBlogs(item)}
            >
              <p>{item}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Blogs;
