import React from "react";
import { RxCross2 } from "react-icons/rx";

const SuccessDialog = ({ message, handleCross }) => {
  return (
    <div style={{
      zIndex: 50
    }} className="fixed top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%] ">
    <div className="relative w-full md:w-[400px] h-[300px] bg-smoke p-[20px] flex flex-col items-center justify-center gap-[30px] rounded-xl shadow-xl">
      <div>
        <svg
          id="success"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="green"
        >
          <path d="M5.48 10.089l1.583-1.464c1.854.896 3.028 1.578 5.11 3.063 3.916-4.442 6.503-6.696 11.311-9.688l.516 1.186c-3.965 3.46-6.87 7.314-11.051 14.814-2.579-3.038-4.301-4.974-7.469-7.911zm14.407.557c.067.443.113.893.113 1.354 0 4.962-4.038 9-9 9s-9-4.038-9-9 4.038-9 9-9c1.971 0 3.79.644 5.274 1.723.521-.446 1.052-.881 1.6-1.303-1.884-1.511-4.271-2.42-6.874-2.42-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11c0-1.179-.19-2.313-.534-3.378-.528.633-1.052 1.305-1.579 2.024z" />
        </svg>
      </div>

      <div>
        <p className="w-full text-[17px] font-poppins text-deepBlue text-center px-[20px] whitespace-normal ">{message}</p>
      </div>

      <div
        onClick={handleCross}
        className="bg-smoke cursor-pointer absolute top-[20px] right-[20px] w-[30px] h-[30px] rounded-full shadow-lg flex justify-center items-center"
      >
        <RxCross2 className="text-xl text-deepBlue" />
      </div>
    </div>
    </div>
  );
};

export default SuccessDialog;
